import React from 'react';
import { setReportingParams, parseReportingParams, convertReportingDefaults, updateURLWithReportingParams } from '../../utils/reporting';
import Table from '../Table';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { getReportSync } from '../../api/reportingAPI';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableInputsFilter from '../TableInputsFilter';
import SearchBar from '../SearchBar';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '../Tooltip';
import { getReportsList, filterCounter, getReportsOptions } from './WidgetReportsUtil';
import LoaderOverlay from '../LoaderOverlay';


class TableWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportsList: null,
            reportData: null,
            reportingParams: {},
            columnsToShow: [],
            checked: {},
            widgetEditMode: false,
            pageSize: null,
            title: ''
        };

        const properties = JSON.parse(this.props.properties);
        this.state.properties = properties;
        this.state.reportingParams = properties && properties.reportingParams ? properties.reportingParams : parseReportingParams();

        this.searchBarRef = React.createRef();
        this.inputFiltersRef = React.createRef();
        if (this.props.layout && this.props.layout[this.props.position]) {
            this.state.pageSize = 8 + (3 * (this.props.layout[this.props.position].h - 4))
        }
    }

    componentDidMount() {
        if (this.props.staged) {
            this.setState({ widgetEditMode: true })
            this.props.toggleDraggablePause('on')
        }

        getReportsList('table').then((response) => {
            this.setState(() => ({ reportsList: response }));
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.layout !== this.props.layout && this.state.pageSize === null) {
            this.setState(() => ({ pageSize: 8 + (3 * (this.props.layout[this.props.position].h - 4)) }), () => {
                this.fetchDataWithCount(null, this.state.pageSize);
            })
        }
        if (this.state.pageSize && prevProps.layout[this.props.position].h !== this.props.layout[this.props.position].h) {
            this.setState(() => ({ pageSize: 8 + (3 * (this.props.layout[this.props.position].h - 4)) }), () => {
                this.fetchDataWithCount(null, this.state.pageSize)
            })
        }
        if (this.props.properties !== prevProps.properties) {
            this.setState(() => ({ properties: JSON.parse(this.props.properties) }))
        }

        if (prevProps.staged !== this.props.staged) {
            this.getWidgetData(JSON.parse(this.props.properties).reportingParams)
        }
    }

    getWidgetData = async (newReportingParams) => {
        let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

        getReportSync(this.state.properties.report_uuid, reportingParams).then(response => {
            if (response.nextPage !== undefined && response.nextPage > this.state.reportingParams.page) {
                reportingParams.nextPage = response.nextPage;
            }
            let checked = {};
            response.data.outputs.forEach((output) => {
                if (this.props.staged) {
                    checked[output.name] = true
                } else {
                    if (this.state.properties.columnsToShow && this.state.properties.columnsToShow.some(column => output.name === column)) {
                        checked[output.name] = true
                    } else {
                        checked[output.name] = false
                    }
                }
            })
            this.setState(() => ({
                reportData: response.data, reportingParams,
                checked, title: this.props.title
            }));
        });
    }

    fetchDataWithCount = (direction, count) => {
        if (count) {
            this.setState(() => ({ reportingParams: { ...this.state.reportingParams, count: count } }), () => {
                this.getWidgetData(this.state.reportingParams)
            })
        }
        if (direction) {
            let newReportingParams = setReportingParams(this.state.reportingParams, { direction });
            if (newReportingParams !== undefined) {
                this.getWidgetData(newReportingParams);
            }
        }
    }

    setFilters = (filters, noUpdate) => {
        let newReportingParams = setReportingParams(
            this.state.reportingParams,
            { filters },
            false
        );
        if (!noUpdate) {
            this.getWidgetData(newReportingParams);
        }
        return newReportingParams;
    }

    setOrdering = (column) => {
        let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
        this.getWidgetData(newReportingParams);
    }

    openNewWindow = () => {
        const filteredReportingParams = this.state.reportingParams;
        delete filteredReportingParams['count']
        const newURL = updateURLWithReportingParams(
            filteredReportingParams,
            `/reporting/${this.state.reportData.report_uuid}`,
            convertReportingDefaults(this.state.reportData.inputs),
            "sendBack"
        )
        window.open(newURL.toLowerCase());
    }

    displayOutputs = () => {

        let options = [];

        if (this.state.reportData) {

            this.state.reportData.outputs.forEach((output) => {
                if (!output.name.includes('UUID') && output.name !== 'Actions') {
                    let option = <FormControlLabel
                        key={output.name}
                        control={
                            <Checkbox
                                checked={this.state.checked[output.name]}
                                onChange={() => { this.handleCheckboxChange(output); }}
                                value={output.name}
                                color='primary'
                            />
                        }
                        label={output.name}
                    />
                    options.push(option);
                }
            });
            return options;
        }

    }

    handleCheckboxChange = (output) => {
        let checked = this.state.checked;
        checked[output.name] = !checked[output.name];
        this.setState({ checked });
    }

    addWidget = (reportChange) => {
        let columns = [];
        let skipUpdate = false;

        if (reportChange) {
            skipUpdate = true
        }

        this.state.reportData.outputs.forEach((output) => {
            if (this.state.checked[output.name]) {
                columns.push(output.name);
            }
        });

        if (!this.props.staged) {
            this.setState(() => ({ columnsToShow: columns }), () => {
                this.props.updateWidget(
                    {
                        "properties":
                            JSON.stringify({
                                ...this.state.properties,
                                columnsToShow: this.state.columnsToShow,
                                reportingParams: this.setFilters({ ...this.inputFiltersRef.current.state.values, ...this.searchBarRef.current.state.values }, skipUpdate)
                            }),
                        "title": this.state.title
                    },
                    this.props.dashboard_widget_uuid,
                    this.props.position
                ).then(() => {
                    if (!reportChange) {
                        this.handleClose();
                    }
                })
            })
        } else {
            this.setState(() => ({ columnsToShow: columns }), () => {
                this.props.addWidget({
                    ...this.state.reportData,
                    title: this.state.title,
                    coords: this.props.layout[this.props.position],
                    columnsToShow: this.state.columnsToShow,
                    reportingParams: this.setFilters({ ...this.inputFiltersRef.current.state.values, ...this.searchBarRef.current.state.values })
                }, 'create')
                this.handleClose()
            })
        }
    }

    deleteWidget = () => {
        if (confirm(`Are you sure you want to delete ${this.state.title} widget?`)) {
            this.props.deleteWidget(this.props.dashboard_widget_uuid, this.props.position)
        }
    }

    toggleWidgetEditMode = () => {
        this.setState((prevState) => ({ widgetEditMode: !prevState.widgetEditMode }))
        this.props.toggleDraggablePause('on')
    }

    cancelEdit = () => {
        if (this.props.staged) {
            this.props.addWidget('cancel')
        }
        this.handleClose();
        this.getWidgetData();
    }

    handleClose = () => {
        this.setState(() => ({ widgetEditMode: false }));
        this.props.toggleDraggablePause('off')
    }

    onTitleChange = (value) => {
        this.setState(() => ({ title: value }));
    }

    handleSelectedReportChange = (e) => {
        const selectedReport = this.state.reportsList[e.target.value]
        let checked = {}

        selectedReport.outputs.forEach((output) => {
            checked[output.name] = true
        })

        if (!this.props.staged) {
            if (confirm('Selecting a new report will permanently change this widget. Proceed?')) {
                this.setState({
                    reportData: selectedReport,
                    checked,
                    title: selectedReport.name,
                    properties: { ...this.state.properties, report_uuid: selectedReport.report_uuid }
                }, () => {
                    this.addWidget('reportChange')
                })
            }
        } else {
            this.setState({
                reportData: selectedReport,
                checked,
                title: selectedReport.name,
                properties: { ...this.state.properties, report_uuid: selectedReport.report_uuid }
            })
        }
    }

    toggleGroupSelection = (type) => {
        if (type === 'all') {
            this.setState(() => ({ checked: _.mapValues(this.state.checked, () => true) }));
        } else if (type === 'none') {
            this.setState(() => ({ checked: _.mapValues(this.state.checked, () => false) }));
        }
    }

    render() {
        const properties = JSON.parse(this.props.properties);
        let editModeTitle;

        if (this.props.staged) {
            editModeTitle = 'Table';
        } else {
            editModeTitle = this.props.title;
        }

        return (
            <React.Fragment>
                <Dialog
                    open={this.state.widgetEditMode ? true : false}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className="widget__dialog"
                >
                    <DialogTitle id="form-dialog-title">{`${editModeTitle} Widget Options`}</DialogTitle>
                    <DialogContent>
                        <div className="widget__options">
                            <div>
                                <span>{this.props.staged ? 'Choose A Report' : 'Report'}</span>
                                <select id="selectedReport" onChange={(e) => this.handleSelectedReportChange(e)}>
                                    <option></option>
                                    {getReportsOptions(this.state.reportsList, 'table', this.state.properties)}
                                </select>
                            </div>
                            {this.state.reportData &&
                                <React.Fragment>
                                    <div>
                                        <span>Title</span>
                                        <input type='text' value={this.state.title} onChange={(e) => this.onTitleChange(e.target.value)}></input>
                                    </div>
                                    <div>
                                        <span>{this.props.staged ? 'Choose Table Columns' : 'Table Columns'}</span>
                                        <div className="widget__options-groupSelection">
                                            <button onClick={() => this.toggleGroupSelection('all')}>Select All</button>
                                            <button onClick={() => this.toggleGroupSelection('none')}>Select None</button>
                                        </div>
                                        <div className="columnsToShow">
                                            {this.displayOutputs()}
                                        </div>
                                    </div>
                                    {this.state.reportData && this.state.reportingParams &&
                                        <div>
                                            <span>Search</span>
                                            <div className="widget__options-groupSelection">
                                                <button onClick={() => this.searchBarRef.current.handleClear()}>Clear</button>
                                            </div>
                                            <SearchBar
                                                data={this.state.reportData}
                                                params={this.state.reportingParams}
                                                widget={true}
                                                ref={this.searchBarRef}
                                            />
                                        </div>}
                                    {this.state.reportData && this.state.reportingParams &&
                                        <div style={_.isEmpty(this.state.reportData.inputs) ? { display: 'none' } : null}>
                                            <span>Filters</span>
                                            <div className="widget__options-groupSelection">
                                                <button onClick={() => this.inputFiltersRef.current.handleClear()}>Clear</button>
                                            </div>
                                            <TableInputsFilter
                                                data={this.state.reportData}
                                                params={this.state.reportingParams}
                                                widget={true}
                                                ref={this.inputFiltersRef}
                                            />
                                        </div>
                                    }
                                </React.Fragment>}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {this.state.reportData && <button className="medButtonPrimary" onClick={() => this.addWidget()}>{this.props.staged ? 'Set Widget' : 'Update Widget'}</button>}
                        <button className="medButtonSecondary" onClick={() => this.cancelEdit()}>Cancel</button>
                    </DialogActions>
                </Dialog>
                <div className="widget widget__table" id={this.props.dashboard_widget_uuid}>
                    <span className="widget__title">{this.props.title}
                        <div className="widget__filters">
                            {this.state.properties.reportingParams && !_.isEmpty(this.state.properties.reportingParams.search) &&
                                <div>
                                    {!this.state.widgetEditMode && <Tooltip text="Active Search" noIcon={true} position="top" />}
                                    <SearchIcon fontSize="inherit" color="inherit" />
                                </div>
                            }
                            {filterCounter(this.state.properties, this.state.reportData) > 0 &&
                                <div>
                                    {!this.state.widgetEditMode && <Tooltip text="Active Filters" noIcon={true} position="top" />}
                                    <FilterListIcon fontSize="inherit" color="inherit" />
                                </div>
                            }
                        </div>
                    </span>
                    {(this.state.reportData && this.state.reportData.rows && !this.props.staged) ? (
                        <Table
                            clickable={false}
                            tableSize='widget'
                            tableType='Data'
                            tableData={this.state.reportData}
                            fetchData={this.fetchDataWithCount}
                            paginationParams={this.state.reportingParams}
                            columnsToShow={properties.columnsToShow}
                            setOrdering={this.setOrdering}
                            pageSize={this.state.pageSize}
                        />
                    ) : <LoaderOverlay />}
                    {this.props.editMode && !this.props.staged && this.state.reportData && this.state.reportData.rows &&
                        <div className="widget__controls widget__controls-editMode">
                            <React.Fragment>
                                <div>
                                    <EditRoundedIcon onClick={() => this.toggleWidgetEditMode()} fontSize="inherit" />
                                </div>
                                <div className="widget__controls-delete">
                                    <DeleteForeverIcon onClick={() => this.deleteWidget()} fontSize="inherit" />
                                </div>
                            </React.Fragment>
                        </div>
                    }
                    {(!this.state.widgetEditMode && !this.props.staged) && !this.props.editMode &&
                        <div className="widget__controls">
                            <div>
                                <OpenInNewIcon onClick={() => this.openNewWindow()} fontSize="inherit" />
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default TableWidget;