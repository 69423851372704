import React, { useEffect, useState } from "react";
import { getErrorText } from "../../../utils/errors";
import { defaultColumnFormats } from "../../../utils/columnFormats";
import { AddRounded, RemoveRounded } from "@material-ui/icons";
import styles from "../../../styles/common/multiStepFormReportTable.module.css";
import LoadingSpinner from "../LoadingSpinner";

/**
 * Renders a simple report table for multi-step forms (no search, filters, or column selection)
 *
 * @param {string} reportUUID - The report to be fetched
 * @param {string[]} [visibleColumns] - Array of columns that are shown in the table
 * @param {Object} [columnFormats] - Object containing functions called when rendering each cell, can be used to alter contents
 * @param {Object} formState - The current form state
 * @param {Function} onFormStateChange - Callback function to update the form state in the parent component
 * @param {Object} tableSelections - Object containing table selection state
 * @param {Function} setTableSelections - Function to update table selection state
 * @param {Object} columnConfig - Object containing column configuration for table rendering
 * @param {Function} fetchAndSetReportData - Function to fetch and set report data
 * @param {Object} templateData - Template data for the report
 * @param {string} tableKey - Key to identify the table
 * @param {Function} onTableSelectionsChange - Callback function to update the table selections in the parent component
 * @param {number} categoryIndex - Index of the category for the report
 */
const MultiStepFormReportTable = ({
    reportUUID,
    visibleColumns,
    columnFormats,
    formState,
    onFormStateChange,
    tableSelections,
    setTableSelections,
    columnConfig,
    fetchAndSetReportData,
    templateData,
    tableKey,
    onTableSelectionsChange,
    categoryIndex,
}) => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const uniqueKey = `${tableKey}_${categoryIndex}`;

    const reportObject = templateData?.reports?.find(
        (report) => report.report_uuid === reportUUID && report.key === tableKey
    );

    useEffect(() => {
        if (!formState[reportUUID]?.[uniqueKey] && templateData) {
            fetchAndSetReportData(reportUUID, formState.industrial_user, tableKey, categoryIndex);
        }
    }, [reportUUID, formState.industrial_user, templateData, fetchAndSetReportData]);

    useEffect(() => {
        if (formState[reportUUID]?.[uniqueKey]) {
            const reportData = formState[reportUUID][uniqueKey];
            setData(reportData);
        }
    }, [formState, reportUUID, uniqueKey, templateData]);

    const isColumnVisible = (col) => {
        const reportObject = templateData?.reports?.find(
            (report) => report.report_uuid === reportUUID && report.key === tableKey
        );
        const hideColumns = reportObject?.hide_columns || [];
        const uuidOrIdRegex = /[^a-z](?:uuid|id)$/i;
        return (
            !col.match(uuidOrIdRegex) &&
            (!visibleColumns?.length || visibleColumns.includes(col)) &&
            !hideColumns.includes(col)
        );
    };

    // Filter out UUID columns and hidden columns from the outputs array
    const filteredOutputs = data?.outputs?.filter((output) => isColumnVisible(output.name));

    // Use the unique key for selection toggling
    const toggleSelection = (row, originalIndex) => {
        setTableSelections((prevSelections) => {
            const newSelections = {
                ...prevSelections,
                [uniqueKey]: {
                    ...(prevSelections[uniqueKey] || {}),
                    [originalIndex]: !prevSelections[uniqueKey]?.[originalIndex],
                },
            };
            onTableSelectionsChange?.(newSelections);
            return newSelections;
        });
    };

    const renderTableRows = () => {
        const isGridTable = reportObject?.table_type === "grid-table";
        const rowsToRender = (data?.rows || []).map((row, index) => ({ row, originalIndex: index }));

        return rowsToRender.map(({ row, originalIndex }) => {
            const isSelected = tableSelections[uniqueKey]?.[originalIndex] || false;
            return (
                <tr key={`row-${originalIndex}`} className={isSelected ? styles.selected : ""}>
                    {!isGridTable && (
                        <td className={styles.selectionColumn}>
                            <div
                                className={styles.selectionIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSelection(row, originalIndex);
                                }}
                            >
                                {isSelected ? (
                                    <RemoveRounded className={styles.icon} />
                                ) : (
                                    <AddRounded className={styles.icon} />
                                )}
                            </div>
                        </td>
                    )}
                    {filteredOutputs.map((output, index) => {
                        const { name, type } = output;
                        if (!isColumnVisible(name)) return null;

                        const columnIndex = data?.outputs?.findIndex((o) => o.name === name);
                        const value = row.values[columnIndex];

                        const formatFunction = columnFormats?.[name] ?? defaultColumnFormats[type]?.format;
                        const config = columnConfig[name] || {};

                        return (
                            <td key={`row-${originalIndex}-${name}`}>
                                {config.render ? (
                                    config.render({
                                        value,
                                        isSelected,
                                        sampleReportConfigID:
                                            row.values[
                                                data?.outputs?.findIndex((o) => o.name === "Sample Report Config ID")
                                            ],
                                        rowIndex: originalIndex,
                                    })
                                ) : (
                                    <div>{formatFunction ? formatFunction(value) : value}</div>
                                )}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    if (error) {
        return getErrorText(error);
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={styles.scrollableWrapper}>
            <h3 className={styles.tableHeader}>{reportObject?.label || data?.name}</h3>
            {data?.rows?.length > 0 ? (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    {reportObject.table_type !== "grid-table" && (
                                        <th className={styles.selectionColumn}></th>
                                    )}
                                    {filteredOutputs.map(
                                        (output, index) =>
                                            isColumnVisible(output.name) && (
                                                <th key={`${output.name}-${index}`}>{output.name}</th>
                                            )
                                    )}
                                </tr>
                            </thead>
                            <tbody>{renderTableRows()}</tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className={styles.noRowsFound}>No Rows Found</div>
            )}
        </div>
    );
};

export default MultiStepFormReportTable;
