import React from 'react';
import TextField from '@material-ui/core/TextField';
import ReactTable from 'react-table';
import MessageBox from '../MessageBox';
import { getPropertiesReport, getProperty } from '../../api/propertiesAPI';
import { getPropertyEquipmentReport, getEquipmentList, getEquipmentProperty } from '../../api/equipmentAPI';
import { getReportSync } from '../../api/reportingAPI';
import { getReportValue } from '../../utils/reporting';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Tooltip } from '../Tooltip';
import { withRouter } from 'react-router-dom';

import checkGreen from '../../../images/checkGreen.svg';
import xRed from '../../../images/xRed.svg';
import moreHoriz from '../../../images/moreHoriz.svg';
import Moment from 'react-moment';

const styles = theme => ({
	textFieldInput: {
		height: 5,
		fontSize: '1.4rem',
		color: '#506788',
	},
	label: {
		'&$focused': {
			color: '#506788'
		},
	},
	focused: {},
	outlinedInput: {
		'&$focused $notchedOutline': {
			borderColor: '#506788',
			borderWidth: 1
		},
	},
	notchedOutline: {},
});

class TestReportLocationAssemblyInfo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			locationUUID: null,
			locationSearchResults: null,
			locationSearchError: "",
			locationSearchErrorLevel: "error",
			locationDetails: null,
			showAssemblySearch: false,
			assemblyUUID: null,
			assemblySearchResults: null,
			assemblySearchError: "",
			assemblySearchErrorLevel: "error",
			searchType: 'location'
		};

		const { location } = this.props;
		if (location.state) {
			this.handleLocationSelection({ uuid: location.state.property_uuid });
			this.state.locationDetails = location.state;
		}
	}

	// use the name and address to find out location / a small set of locations
	locationSearch = (type) => {

		if (this.state.locationDetails) {
			this.setState({ locationDetails: null });
		}

		let payload;

		if (type === 'location') {
			this.toggleSearchType('location');

			let name = document.getElementById('location_name');
			let address = document.getElementById('location_address');
			let serial = document.getElementById('serial_number');
			serial.value = "";
			if (address.value == "") {
				this.setState({
					locationSearchError: "You must specify an address to search",
					assemblySearchErrorLevel: "error",
					assemblySearchError: ""
				});
				return;
			} else if (address.value.length < 3) {
				this.setState({
					locationSearchError: "Minimum 3 characters required",
					locationSearchErrorLevel: "error",
					assemblySearchError: ""
				});
				return
			}

			payload = { search: { name: name.value, address: address.value }, count: "250" };

			getPropertiesReport(payload).then(response => {
				if (response.data.rows.length === 0) {
					this.setState({
						locationSearchError: "No locations found, please revise your search or create a new one",
						locationSearchErrorLevel: "info",
						locationSearchResults: null,
						showAssemblySearch: false,
						assemblySearchError: "",
						assemblySearchResults: null,
						locationUUID: null
					});
				} else {
					this.setState({
						locationSearchError: "",
						locationSearchErrorLevel: "error",
						locationSearchResults: response.data,
						showAssemblySearch: false,
						assemblySearchError: "",
						assemblySearchResults: null,
						locationUUID: null
					}, () => {
						if (response.data.rows.length === 1) {
							this.handleLocationSelection(this.convertLocationReportRow(response.data.rows[0]));
						}
					});
				}
			}).catch(error => {
				this.setState({
					locationSearchError: error.message,
					locationSearchErrorLevel: "error",
					locationSearchResults: null,
					showAssemblySearch: false,
					assemblySearchError: "",
					assemblySearchResults: null,
					locationUUID: null
				});
			});

		} else {

			getProperty(this.state.locationUUID).then((response) => {
				this.setState({
					locationSearchError: "",
					locationSearchErrorLevel: "error",
					locationSearchResults: { uuid: response.property_uuid, name: response.name, address: response.address1 },
					showAssemblySearch: false,
					assemblySearchError: ""
				});
			}).catch(error => {
				this.setState({
					locationSearchError: 'This assembly is not currently connected to a location. You will need to provide the complete location information when filling out this report.',
					locationSearchErrorLevel: "info",
					locationSearchResults: null,
					showAssemblySearch: false,
					assemblySearchError: "",
					locationUUID: null
				});
			})


		}
	}

	convertLocationReportRow = (row) => {
		let { locationSearchResults } = this.state;

		return {
			uuid: getReportValue(locationSearchResults, row, 'Property UUID'),
			name: getReportValue(locationSearchResults, row, 'Name'),
			address: getReportValue(locationSearchResults, row, 'Address')
		};
	}

	// return a react table of the locations from the above report call
	getLocationsTable = () => {
		let { locationSearchResults } = this.state;

		if (locationSearchResults === null) {
			return null;
		}

		let data = [];

		if (this.state.searchType === 'location') {
			locationSearchResults.rows.forEach(row => {
				data.push(this.convertLocationReportRow(row));
			})
		} else {
			data.push(locationSearchResults);
		}

		let columns = [];

		[
			{ key: 'name', header: 'Name' },
			{ key: 'address', header: 'Address' }
		].forEach(obj => {
			columns.push({
				show: true,
				accessor: obj.key,
				Header: obj.header,
				Cell: ({ original }) => (
					<div>
						{original[obj.key]}
					</div>
				)
			});
		});

		columns.push(<span></span>);
		columns.unshift(<span></span>);

		return (
			<React.Fragment>
				<div className="locationAssemblyInfo__header-wrapper">
					<span className='locationAssemblyInfo__header'>{this.state.searchType === 'location' ? 'Choose Location' : 'Assembly Location'}</span>
				</div>
				{!this.props.source && this.state.searchType === 'location' &&
					<span className='locationAssemblyInfo__inputs-subtext'>
						<span><button className="buttonSecondary" onClick={this.handleNewLocation}>New Location</button></span>
					</span>
				}
				<div className="mainTable">
					<ReactTable
						data={data}
						columns={columns}
						resizable={false}
						sortable={true}
						minRows={0}
						showPagination={false}
						getProps={
							(state, rowInfo, column, instance) => {
								return {
									style: {
										height: 'auto'
									},
								};
							}
						}
						getTrProps={(state, rowInfo, column, instance) => {
							return {
								onClick: () => {
									if (this.state.searchType === 'location') {
										this.handleLocationSelection(rowInfo.original);
										this.setState({ selectedLocation: rowInfo.index });
									}
								},
								style: {
									background:
										(rowInfo.index === this.state.selectedLocation || data.length === 1) ? '#b7c5d8' : null
								}
							};
						}}
						getTheadThProps={(state, rowInfo, column, instance) => {
							return {
								style: {
									display: 'flex'
								}
							};
						}}
					/>
				</div>
				{!this.props.source && this.state.searchType === 'serialNumber' &&
					<span className='locationAssemblyInfo__inputs-subtext' style={{ marginTop: '0' }}>
						*If this location is incorrect, please select a different assembly or contact your water utility backflow coordinator
					</span>
				}
			</React.Fragment>
		);
	}

	// once we select a location, use that uuid to look up the list of assemblies there, set that uuid in our state too
	// since this will re-render and we want to make sure we check the radio again in the table
	handleLocationSelection = (rowInfo) => {
		let uuid = rowInfo.uuid;

		let payload = { search: { "Property UUID": uuid }, inputs: { "Active": "null" }, "order": "active, serial number", "direction": "desc", count: "250" };

		let showAssemblySearch = false;
		getPropertyEquipmentReport(payload).then(response => {
			// if this location has a next page of results, we want to show the search bar for assemblies
			if (response.data.rows.length > 10) {
				showAssemblySearch = true;
			}
			this.setState(
				{ locationUUID: uuid, assemblySearchResults: response.data, showAssemblySearch },
				() => {
					if (response.data.rows.length === 1) {
						this.handleAssemblySelection(this.convertAssemblyReportRow(response.data.rows[0]));
					}
				}
			);
		}).catch(error => {
			this.setState({ locationUUID: uuid, assemblySearchError: error.message, showAssemblySearch });
		})
	}

	// use the name and address to find out location / a small set of locations
	assemblySearch = async (type, locationSearch) => {
		let sn;
		let name = document.getElementById('location_name');
		let address = document.getElementById('location_address');
		name.value = "";
		address.value = "";

		if (locationSearch) {
			sn = document.getElementById('serial_number_location_search');
		} else {
			sn = document.getElementById('serial_number');
			this.toggleSearchType('serialNumber');
		}

		if (sn.value.length < 3 && !locationSearch) {
			this.setState({
				assemblySearchError: "Minimum 3 characters required",
				assemblySearchErrorLevel: "error",
				locationSearchError: ""
			});
			return
		}

		if (type !== 'serialNumber') {
			let payload = { search: { "property uuid": this.state.locationUUID, "serial number": sn.value }, inputs: { "Active": "null" }, count: "250" };
			getPropertyEquipmentReport(payload).then(response => {
				if (response.data.rows.length === 0) {
					this.setState({
						assemblySearchError: "No assemblies found, please revise your search or search by address instead.",
						assemblySearchErrorLevel: "info",
						assemblySearchResults: response.data,
						assemblyUUID: null
					});
				} else {
					this.setState({
						assemblySearchError: "",
						assemblySearchErrorLevel: "error",
						assemblySearchResults: response.data,
						assemblyUUID: null
					}, () => {
						if (response.data.rows.length === 1) {
							this.handleAssemblySelection(this.convertAssemblyReportRow(response.data.rows[0]));
						}
					});
				}
			}).catch(error => {
				this.setState({
					assemblySearchError: error.message,
					assemblySearchErrorLevel: "error",
					assemblySearchResults: null,
					assemblyUUID: null
				});
			});
		} else {
			if (this.state.locationDetails) {
				this.setState({ locationDetails: null });
			}
			getReportSync('b54a82b3-ae76-4c06-94cb-8098bdbf4e56', { "inputs": { "active": "null" }, "search": { "serial number": sn.value }, "order": "active, serial number", "direction": "desc", count: "250" }).then((assembliesList) => {
				if (assembliesList.data.rows.length === 0) {
					this.setState({
						assemblySearchError: "No assemblies found, please revise your search or search by address instead.",
						assemblySearchErrorLevel: "info",
						assemblySearchResults: assembliesList.data
					});
				} else {
					this.setState({
						assemblySearchError: "",
						assemblySearchErrorLevel: "error",
						assemblySearchResults: assembliesList.data
					}, () => {
						if (assembliesList.data.rows.length === 1) {
							this.handleAssemblySelection(this.convertAssemblyReportRow(assembliesList.data.rows[0]));
						}
					});
				}
			}).catch(error => {
				this.setState({
					assemblySearchError: error.message,
					assemblySearchErrorLevel: "error",
					assemblySearchResults: null
				});
			});
		}
	}

	handleClear = () => {
		window.location.href = '/testReports/newTestReport';
	};

	convertAssemblyReportRow = (row) => {
		let { assemblySearchResults } = this.state;

		return {
			uuid: getReportValue(assemblySearchResults, row, 'Equipment UUID'),
			serial_number: getReportValue(assemblySearchResults, row, 'Serial Number'),
			type: getReportValue(assemblySearchResults, row, 'Type'),
			make: getReportValue(assemblySearchResults, row, 'Make'),
			model: getReportValue(assemblySearchResults, row, 'Model'),
			size: getReportValue(assemblySearchResults, row, 'Size'),
			active: getReportValue(assemblySearchResults, row, 'Active'),
			property: getReportValue(assemblySearchResults, row, 'Property UUID'),
			compliant: getReportValue(assemblySearchResults, row, 'Compliant'),
			assembly_location: getReportValue(assemblySearchResults, row, 'Assembly Location'),
			compliance_expiration: getReportValue(assemblySearchResults, row, 'Compliance Expiration'),
		};
	}

	// like locations, use the report above to return a react table of the assemblies
	getAssembliesTable = () => {
		let { assemblySearchResults } = this.state;

		if (assemblySearchResults === null) {
			return null;
		}

		let data = [];

		assemblySearchResults.rows.forEach(row => {
			data.push(this.convertAssemblyReportRow(row));
		});

		let columns = [];

		[
			{ key: 'active', header: 'Active'},
			{ key: 'serial_number', header: 'Serial #' },
			{ key: 'type', header: 'Type' },
			{ key: 'make', header: 'Make' },
			{ key: 'model', header: 'Model' },
			{ key: 'size', header: 'Size'},
			{ key: 'compliant', header: 'Compliant'},
			{ key: 'compliance_expiration', header: 'Next Test Due'},
			{ key: 'assembly_location', header: 'Location'}
		].forEach(obj => {
			
			if (obj.key === 'active') {
				columns.push({
					show: true,
					accessor: obj.key,
					Header: obj.header,
					Cell: ({ original }) => (
						<div>
							{original['active'] === 'true' ? <p style={{color: '#00D89D'}}>Active</p> : <p style={{color: '#FF005C'}}>Inactive</p> }
						</div>
					)
				});
			} else if (obj.key === 'compliant') {
				columns.push({
					show: true,
					accessor: obj.key,
					Header: obj.header,
					Cell: ({ original }) => (
						<div>
							{original['compliant'] === 'true' ? <img src={checkGreen} alt='Compliant' /> : original['compliant'] === 'false' ? <img src={xRed} alt='Non Compliant' /> : <img src={moreHoriz} alt='Unknown' />  }
						</div>
					)
				});
			} else if (obj.key === 'compliance_expiration') {
				columns.push({
					show: true,
					accessor: obj.key,
					Header: obj.header,
					Cell: ({ original }) => (
						<div>
							{original['compliance_expiration'] ? <Moment unix format="YYYY-MM-DD">{original['compliance_expiration']}</Moment> : 'N/A'}
						</div>
					)
				});

			} else if (obj.key === 'assembly_location') {
				columns.push({
					show: true,
					accessor: obj.key,
					Header: obj.header,
					minWidth: 200,
					Cell: ({ original }) => (
						<div style={{position: "relative"}}>
							<Tooltip text={original['assembly_location']} noIcon={true} />
							{original['assembly_location']}
						</div>
					)
				});
			} else {
				columns.push({
					show: true,
					accessor: obj.key,
					Header: obj.header,
					Cell: ({ original }) => (
						<div>
							{original[obj.key]}
						</div>
					)
				});
			}
		});

		columns.push(<span></span>);
		columns.unshift(<span></span>);

		return (
			<React.Fragment>
				{(data.length > 0 || this.state.showAssemblySearch) ?
					<React.Fragment>
						{this.state.locationDetails && 
						<div className="locationAssemblyInfo__header-wrapper locationDetails" style={{marginBottom: "2rem", alignItems: "baseline"}}>
							<span className='locationAssemblyInfo__header'>Location:</span>
							<div className="location_details">
								<span>{this.state.locationDetails.property_name}</span>
								<span>{this.state.locationDetails.property_address}</span>
								<span>{`${this.state.locationDetails.property_city}, ${this.state.locationDetails.property_state}`}</span>
							</div>
						</div>
						}
						<div className="locationAssemblyInfo__header-wrapper">
							<span className='locationAssemblyInfo__header'>Choose Assembly</span>
						</div>
						{!this.props.source && 
							<span className='locationAssemblyInfo__inputs-subtext'>
								<button className="buttonSecondary" onClick={this.handleNewAssembly}>New / Replacement Assembly</button>
							</span>
						}
						{this.state.showAssemblySearch && (
							<React.Fragment>
								<div className="locationAssemblyInfo__inputs">
									<TextField
										label="Assembly Serial Number"
										name="serial_number_location_search"
										id="serial_number_location_search"
										variant="outlined"
										InputProps={{
											classes: {
												input: this.props.classes.textFieldInput,
												root: this.props.classes.outlinedInput,
												focused: this.props.classes.focused,
												notchedOutline: this.props.classes.notchedOutline,
											}
										}}
										onKeyDown={(e) => {
											if (e.keyCode === 13) {
												this.assemblySearch('location', true);
											}
										}}
									/>
									<button className="xsmallButtonPrimary" onClick={() => this.assemblySearch('location', true)}>
										<SearchIcon fontSize='large' />
									</button>
								</div>
							</React.Fragment>
						)}
						<div className="mainTable">
							<ReactTable
								data={data}
								columns={columns}
								resizable={false}
								sortable={true}
								minRows={0}
								pageSize={data.length}
								showPagination={false}
								noDataText=""
								getProps={
									(state, rowInfo, column, instance) => {
										return {
											style: {
												height: 'auto'
											},
										};
									}
								}
								getTrProps={(state, rowInfo, column, instance) => {
									let rowStyle;
									if (rowInfo.original) {
										if (rowInfo.original["active"] === 'false') {
											rowStyle = {
												fontStyle: 'italic',
												color: (rowInfo.index === this.state.selectedAssembly || data.length === 1) ? null : '#ACBACF',
												background: (rowInfo.index === this.state.selectedAssembly || data.length === 1) ? '#b7c5d8' : null
											}
										} else {
											rowStyle = {
												background: (rowInfo.index === this.state.selectedAssembly || data.length === 1) ? '#b7c5d8' : null
											}
										}
									}
									return {
										onClick: () => {
											this.handleAssemblySelection(rowInfo.original);
											this.setState({ selectedAssembly: rowInfo.index });
										},
										style: rowStyle
									};
								}}
								getTheadThProps={(state, rowInfo, column, instance) => {
									return {
										style: {
											display: 'flex'	
										}
									};
								}}
							/>
						</div>
					</React.Fragment>
					:
					this.state.searchType === 'location' && !this.state.assemblySearchError &&
					<>
					<MessageBox options={{ type: "info", message: "No assemblies found for this location" }} />
					<span className='locationAssemblyInfo__inputs-subtext'>
						<button className="buttonSecondary" onClick={this.handleNewAssembly}>New / Replacement Assembly</button>
					</span>
					</>
				}
			</React.Fragment>
		);
	}

	handleAssemblySelection = async (rowInfo) => {
		if (this.props.source === 'newAssembly') {
			this.props.sendDataToReport(rowInfo, this.state.locationUUID);
		} else {
			let uuid = rowInfo.uuid;
			if (this.state.searchType === 'serialNumber') {
				let location;
				const assemblyLocation = await getEquipmentProperty(uuid);
				if (assemblyLocation.property_uuid) {
					location = assemblyLocation.property_uuid;
				}
				this.setState(() => ({ assemblyUUID: uuid, locationUUID: location }), () => {
					this.locationSearch('serialNumber');
				});
			} else {
				this.setState({ assemblyUUID: uuid });
			}
		}
	}

	startReport = () => {
		this.props.sendDataToReport(this.state.locationUUID, this.state.assemblyUUID);
	}

	handleNewLocation = () => {
		this.props.sendDataToReport(null, null);
	}

	handleNewAssembly = () => {
		this.props.sendDataToReport(this.state.locationUUID, null);
	}

	toggleSearchType = (type) => {
		this.setState(() => ({
			searchType: type,
			locationSearchError: '',
			assemblySearchError: '',
			assemblySearchResults: null,
			locationSearchResults: null,
			assemblyUUID: null,
			locationUUID: null,
			selectedAssembly: null,
			selectedLocation: null,
			showAssemblySearch: false
		}))
	}

	render() {
		const { classes } = this.props;
		return (
			<div className='locationAssemblyInfo' id="locationAssemblyInfo">
				<div className='locationAssemblyInfo__wrapper' >
					<div className="locationAssemblyInfo__header-wrapper">
						<span className='locationAssemblyInfo__header' style={{ paddingRight: ".5rem" }}>Search By Serial Number</span>
						<Tooltip text="Start by searching for just this serial number, avoid using any dashes or letters as those can be added on later to refine your search results." />
					</div>
					<div className="locationAssemblyInfo__inputs">
						<TextField
							label="Assembly Serial Number"
							name="serial_number"
							id="serial_number"
							required
							variant="outlined"
							InputProps={{
								classes: {
									input: classes.textFieldInput,
									root: classes.outlinedInput,
									focused: classes.focused,
									notchedOutline: classes.notchedOutline,
								}
							}}
							onKeyDown={(e) => {
								if (e.keyCode === 13) {
									this.assemblySearch('serialNumber');
								}
							}}
						/>
						<button className="xsmallButtonPrimary" onClick={() => this.assemblySearch('serialNumber')}>
							<SearchIcon fontSize='large' />
						</button>
					</div>
					<h4><span>or</span></h4>
					<div className="locationAssemblyInfo__header-wrapper">
						<span className='locationAssemblyInfo__header' style={{ paddingRight: ".5rem" }}>Search By Location</span>
						<Tooltip text={
							<React.Fragment>
								<p>Location Name: This search field is optional and can be used in conjuction with the Location Address search field to refine your search results.</p>
								<p>Location Address: For best results start with only the address number of your location and expand your search if needed.</p>
							</React.Fragment>
						}
							html={true}
						/>
					</div>
					<div className="locationAssemblyInfo__inputs">
						<TextField
							label="Location Name"
							name="location_name"
							id="location_name"
							variant="outlined"
							InputProps={{
								classes: {
									input: classes.textFieldInput,
									root: classes.outlinedInput,
									focused: classes.focused,
									notchedOutline: classes.notchedOutline,
								}
							}}
							onKeyDown={(e) => {
								if (e.keyCode === 13) {
									this.locationSearch('location');
								}
							}}
						/>
						<TextField
							label="Location Address"
							name="location_address"
							id="location_address"
							required
							variant="outlined"
							InputProps={{
								classes: {
									input: classes.textFieldInput,
									root: classes.outlinedInput,
									focused: classes.focused,
									notchedOutline: classes.notchedOutline,
								}
							}}
							onKeyDown={(e) => {
								if (e.keyCode === 13) {
									this.locationSearch('location');
								}
							}}
						/>
						<button className="xsmallButtonPrimary" onClick={() => this.locationSearch('location')}>
							<SearchIcon fontSize='large' />
						</button>
					</div>
					{this.state.searchType === 'location' ? (
						<React.Fragment>
							{this.getLocationsTable()}
							<MessageBox options={{ type: this.state.locationSearchErrorLevel, message: this.state.locationSearchError }} />
							{this.getAssembliesTable()}
							<MessageBox options={{ type: this.state.assemblySearchErrorLevel, message: this.state.assemblySearchError }} />
						</React.Fragment>) :
						(<React.Fragment>
							<MessageBox options={{ type: this.state.assemblySearchErrorLevel, message: this.state.assemblySearchError }} />
							{this.getAssembliesTable()}
							<MessageBox options={{ type: this.state.locationSearchErrorLevel, message: this.state.locationSearchError }} />
							{this.getLocationsTable()}
						</React.Fragment>)
					}
					{!this.props.source && !this.state.locationSearchResults &&
						<span className='locationAssemblyInfo__inputs-subtext' style={{ marginTop: "2rem" }}>
							<span><button className="buttonSecondary" onClick={this.handleNewLocation}>New Location</button></span>
						</span>
					}
					{this.state.assemblyUUID && (
						<button className="longButtonPrimary" style={{ fontSize: "2rem" }} onClick={this.startReport}>Continue to Service Provider Selection</button>
					)}
				</div>
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(TestReportLocationAssemblyInfo));