import React from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import 'react-table/react-table.css';
import medalSmall from '../../images/medalSmall.svg';
import warningSmall from '../../images/warningSmall.svg';
import horLine from '../../images/horLine.svg';
import edit from '../../images/edit.svg';
import del from '../../images/delete.svg';
// we use these for booleans too
import checkGreen from '../../images/checkGreen.svg';
import xRed from '../../images/xRed.svg';
// pending (booleans)
import moreHoriz from '../../images/moreHoriz.svg';
import Tooltip from './Tooltip';
import Moment from 'react-moment';
import { Checkbox } from '@material-ui/core';

class Table extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			clickable: (props.clickable !== undefined) ? props.clickable : props.multiSelect ? false : true,
			selectAll: 0,
			selected: {},
			dateFormats: (props.dateFormats !== undefined) ? props.dateFormats : {}
		};
	}

	componentDidMount = () => {
		if (this.props.selected) {
			let selected = {}

			this.props.selected.forEach((row) => {
				selected[row] = true
			})

			this.setState(() => ({ selected: selected }));
		}
	}

	defineColumns = (tableData) => {
		let columns = [];
		let lengths = [];
		let specialColumns = {
			'actions': {
				Header: () => (''),
				Cell: () => {
					return (
						<div className="tableItemContainer">
							{this.props.showEdit && (
								<img style={{ padding: '1rem' }} src={edit} alt='' />
							)}
							{this.props.showDel && (
								<img style={{ padding: '1rem' }} src={del} alt='' />
							)}
						</div>
					);
				},
				minWidth: 50
			}
		};

		// Since you can just click on the row to edit it or delete it or whatever these likely won't be used except
		// the delete on tables where you are linking one object to another and want to show the delete to be able to
		// break that link.
		// NOTE that even though we can display them I've been unable to get any sort of onclick to render in the cell
		// so this is all kind of pointless
		if (this.props.showEdit || this.props.showDel) {
			tableData.outputs.push({ name: 'Actions' });
		}

		// TODO Do we really need to do this each time, or really just on the first page, and then reuse the columns
		// stored in state?  Will the rendering / values work right?
		tableData.outputs.forEach((output, idx) => {
			if (lengths[idx] === undefined) {
				lengths[idx] = 0;
			}

			if (specialColumns[output.name.toLowerCase()] !== undefined) {
				columns[idx] = specialColumns[output.name.toLowerCase()];
			} else {
				let showColumn = true;
				// we don't show any of the UUID's in the UI
				if (output.name.endsWith('UUID')) {
					showColumn = false;
				} else if (this.props.dataSource === "list" && this.props.columns[idx].display === false) {
					showColumn = false;
				} else if (this.props.columnsToShow && this.props.columnsToShow.length > 0) {
					showColumn = this.props.columnsToShow.includes(output.name);
				}

				columns[idx] = {
					show: showColumn,
					OrderingHeader: output.name,
					Header: () => {
						return (
							<div className={output.type !== 'string'
								? 'centerHeader' : null}>
								{output.name}
							</div>
						);
					},
					accessor: 'value[" + idx + "]',
					Cell: ({ original }) => {
						let centerContent = { justifyContent: 'center ' };
						if (output.type === 'url') {
							return (
								<div className="tableItemContainer" style={centerContent}>
									<a href={`//${original.values[idx]}`}>{original.values[idx]}</a>
								</div>
							);
						} else if (output.type === 'compliance_report_status') {
							let color = '#7689A3';
							let value = 'In Progress';
							let title = 'In Progress';
							if (original.values[idx] === 'submitted') {
								value = 'Submitted';
								title = 'Submitted For Review';
							} else if (original.values[idx] === 'accepted') {
								color = '#00D89D';
								value = 'Accepted';
								title = 'Accepted';
							} else if (original.values[idx] === 'rejected') {
								color = '#FF005C';
								value = 'Rejected';
								title = 'Rejected';
							} else if (original.values[idx] === 'pending_payment') {
								color = '#FF9800';
								value = 'Pending Payment';
								title = 'Pending Payment';
							}
							return (
								<div className="tableItemContainer" style={centerContent}>
									<p style={{ color: color }} title={title}>{value}</p>
								</div>
							)
						} else if (output.type === 'compliance_result') {
							let color = '#7689A3';
							let value = '?';
							let title = 'Unknown';
							if (original.values[idx] === 'true') {
								color = '#00D89D';
								value = 'Pass';
								title = 'Pass';
							} else if (original.values[idx] === 'false') {
								color = '#FF005C';
								value = 'Fail';
								title = 'Fail';
							}
							return (
								<div className="tableItemContainer" style={centerContent}>
									<p style={{ color: color }} title={title}>{value}</p>
								</div>
							)
						} else if (output.type === 'boolean') {
							let icon = moreHoriz;
							let alt = 'Unknown';
							if (original.values[idx] === 'true') {
								icon = checkGreen;
								alt = 'True';
							} else if (original.values[idx] === 'false') {
								icon = xRed;
								alt = 'False';
							}
							return (
								<div className="tableItemContainer" style={centerContent}>
									<img style={{ padding: '1rem' }} src={icon} title={alt} alt={alt} />
								</div>
							)
						} else if (output.type === 'timestamp') {
							// The default since we don't need much resolution is the day.  However we will allow 
							// components to pass in other formats for individual fields if we want to extend this,
							// notably fields like last modified on can benefit from being YYYY-MM-DD hh:mm
							let dateFormat = "YYYY-MM-DD";
							if (this.state.dateFormats[output.name]) {
								dateFormat = this.state.dateFormats[output.name]
							}

							return (
								<div className="tableItemContainer" style={centerContent}>
									{original.values[idx] && (
										<Moment unix format={dateFormat}>{original.values[idx]}</Moment>
									)}
								</div>
							)
						} else if (output.type === 'certification_status') {
							let icon = horLine;
							let alt = 'No Certifications';
							if (original.values[idx] === 'certified') {
								icon = medalSmall;
								alt = 'All Certifications Valid';
							} else if (original.values[idx] === 'expired') {
								icon = warningSmall;
								alt = 'One or More Certifications Expired';
							}
							return (
								<div className="tableItemContainer" style={centerContent}>
									<img style={{ padding: '1rem' }} src={icon} title={alt} alt={alt} />
								</div>
							)
						} else {
							return (
								<div className="tableItemContainer"
									style={(output.type === 'int' || output.type === 'numeric') ? centerContent : null}
								>
									{original.values[idx]}
								</div>
							);
						}
					}
				};
			}
		});

		tableData.rows.forEach((row, ignore) => {
			// we are going to look at each value in the col and take the max of their lengths and the header's length 
			//(so that doesn't get chopped)
			row.values.forEach((val, idx) => {
				let valLength = 0;
				if (val !== null && val !== undefined) {
					valLength = val.length;
				}

				lengths[idx] = Math.max(
					lengths[idx],
					valLength,
					tableData.outputs[idx].name.length
				);
			});
		});

		lengths.forEach((len, idx) => {
			// now, unless the minWidth is already set, i.e. by a special col, set the min to len + 25% * some multiplier
			// The big problem here is this lib wants a width in px but we use rem on the ui, so the conversion is weird
			// and to make it look decent we want a larger multiplier for small cols, but want to shrink that down as we
			// have more data as it just amplifies the white space
			if (columns[idx].minWidth === undefined) {
				let multiplier = 7;
				if (len < 5) {
					multiplier = 9;
				}
				columns[idx].minWidth = (len * 1.25) * multiplier;
			}
		});


		columns.push(<span></span>);
		if (this.props.multiSelect) {
			columns.unshift({
				id: "checkbox",
				accessor: "",
				Cell: ({ original }) => {
					return (
						<Checkbox
							checked={this.state.selected[original.values] === true}
							onChange={() => this.toggleRow(original.values)}
							color="primary"
							size="medium"
						/>
					)
				},
				Header: title => {
					return (
						<Checkbox
							checked={this.state.selectAll === 1}
							onChange={() => this.toggleSelectAll()}
							id="allCheck"
							color="primary"
							size="medium"
						/>
					)
				},
				sortable: false
			})
		} else {
			columns.unshift(<span></span>);
		}
		return columns;
	}

	toggleRow(row) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[row] = !this.state.selected[row];
		this.setState({
			selected: newSelected,
			selectAll: 2
		}, () => {
			let list = [];

			for (const x in this.state.selected) {
				if (this.state.selected[x] === true) {
					list.push(x)
				}
			}
			this.props.openFlyout(list)
		});
	}

	toggleSelectAll() {
		let newSelected = {};

		if (this.state.selectAll === 0) {
			this.props.tableData.rows.forEach(x => {
				newSelected[x.values] = true;
			});
		}

		this.setState({
			selected: newSelected,
			selectAll: this.state.selectAll === 0 ? 1 : 0
		}, () => {
			let list = [];

			for (const x in this.state.selected) {
				if (this.state.selected[x] === true) {
					list.push(x)
				}
			}
			this.props.openFlyout(list)
		});
	}

	convertAPIListForTable = (tableData, columns) => {
		let conversion = {
			outputs: [],
			rows: []
		};
		// convert an api list call to something that looks like a reporting call
		tableData.forEach((data) => {
			let row = [];
			columns.forEach((col) => {
				let value = "";
				// if the dataName was passed in as an array, we assume we want to join all those values with a space
				if (Array.isArray(col.dataName)) {
					let values = [];
					col.dataName.forEach((name, idx) => {
						if (data[name]) {
							values.push(data[name]);
						}
					});
					value = values.join(" ");
				} else {
					// reporting returns everything as strings, but lists might not so make sure we convert to string
					// so we can assume that is the case later on
					value = "" + data[col.dataName];
				}
				row.push(value);
			})
			conversion.rows.push({ values: row })
		})

		// now toss the columns into a format like reporting as well, nothing will be searchable in this format
		columns.forEach((col) => {
			conversion.outputs.push({
				name: col.displayName,
				type: (col.dataType) ? col.dataType : "string",
				searchable: false
			})
		})
		return conversion;
	}

	render() {
		let { tableSize, tableData } = this.props;
		const columnDefaults = { ...ReactTableDefaults.column, headerClassName: 'wordwrap' };

		if (this.props.dataSource === "list") {
			tableData = this.convertAPIListForTable(tableData, this.props.columns);
		}

		let newTableData;
		if (tableData && tableData.rows) {
			newTableData = tableData.rows;
		}
		else {
			newTableData = tableData;
		}

		let tableStyle, rowStyle;
		if (tableSize) {
			if (tableSize == 'small') {
				tableStyle = {
					height: '52vh'
				};
			} else if (tableSize == 'smallFlex') {
				tableStyle = {
					height: 'auto'
				};
			} else if (tableSize == 'widget') {
				tableStyle = {
					height: (this.props.pageSize > 8 || this.props.pageSize < 8) ? 'auto' : '42.8rem'
				}
			} else if (tableSize == 'flexMaxLimit') {
				tableStyle = {
					height: 'auto',
					maxHeight: '50rem'
				}
			} else {
				tableStyle = null;
			}
		}

		let sel;
		if (this.props.selectedIndex !== undefined) {
			sel = this.props.selectedIndex
		} else if (this.props.flyoutActive) {
			sel = this.state.selected;
		} else if (this.props.highlight) {
			sel = this.state.selected;
		} else {
			sel = null;
		}

		return (
			<div>
				<div className="mainTable">
					<ReactTable
						// key={this.props.pageSize}
						data={newTableData}
						columns={this.defineColumns(tableData)}
						column={columnDefaults}
						noDataText={`No ${this.props.tableType} Found`}
						className="-highlight"
						resizable={false}
						sortable={false}
						minRows={0}
						showPagination={false}
						onFetchData={this.fetchData}
						defaultPageSize={this.props.pageSize ? this.props.pageSize : 50}
						pageSize={this.props.pageSize ? this.props.pageSize : 50}
						// style={{
						// 	height: "100px"
						// }}
						getProps={
							(state, rowInfo, column, instance) => {
								return {
									style: tableStyle,
								};
							}
						}
						getTrProps={(state, rowInfo, column, instance) => {
							if (this.props.tableData.outputs) {
								let outputs = this.props.tableData.outputs;
								let activePosition = outputs.findIndex(output => output.name === 'Active')
								if (rowInfo.original.values[activePosition] === 'false') {
									rowStyle = {
										background: rowInfo.index === sel ? '#b7c5d8' : null,
										cursor: (this.state.clickable) ? 'pointer' : 'default',
										fontStyle: 'italic',
										color: '#ACBACF'
									}
								} else {
									rowStyle = {
										background: rowInfo.index === sel ? '#b7c5d8' : null,
										cursor: (this.state.clickable) ? 'pointer' : 'default'
									}
								}
							} else {
								rowStyle = {
									background: rowInfo.index === sel ? '#b7c5d8' : null,
									cursor: (this.state.clickable) ? 'pointer' : 'default'
								}
							}
							return {
								onClick: () => {
									if (this.state.clickable) {
										this.props.openFlyout(rowInfo.original);
										this.setState({ selected: rowInfo.index });
									}
								},
								style: rowStyle
							};
						}}
						getTheadThProps={(state, rowInfo, column, instance) => {
							return {
								onClick: () => {
									if (this.props.setOrdering !== undefined) {
										this.props.setOrdering(column.OrderingHeader);
									}
								}
							};
						}}
					/>
				</div>
				{! this.props.suppressPagination &&
					<React.Fragment>
						<div className="table-pagination-controls">
							{this.props.paginationParams && this.props.paginationParams.page && this.props.paginationParams.page > 1 && (
								<button className="medButtonSecondary" type="button" onClick={() => { this.props.fetchData('prev') }} data-testid="oldPaginationNavPrev">Previous Page</button>
							)}
							{this.props.paginationParams && this.props.paginationParams.nextPage > this.props.paginationParams.page && (
								<button className="medButtonSecondary" type="button" onClick={() => { this.props.fetchData('next') }} data-testid="oldPaginationNavNext">Next Page</button>
							)}
						</div>
						{this.props.requestID && (
							<span style={{ fontSize: '30%', color: 'lightgray' }}>report run id: {this.props.requestID}</span>
						)}
					</React.Fragment>
				}
			</div >
		);
	}
}

export default Table;