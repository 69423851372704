import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { ClickAwayListener } from '@material-ui/core';
import dropdown from '../../../images/dropdown.svg';
import { getReportSync } from '../../api/reportingAPI';
import { getServiceProviderBalance, getComplianceReportPrice, initiatePaymentProcess } from '../../api/paymentsAPI';
import { getOrganizationSetting } from '../../api/organizationAPI';
import { getComplianceReport } from '../../api/complianceAPI';
import { getProperty } from '../../api/propertiesAPI';
import { getEquipment } from '../../api/equipmentAPI';
import { getReportValue } from '../../utils/reporting';
import * as Moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { loadStripe } from '@stripe/stripe-js';
import { updateURL } from '../../utils/url';
import NumberFormat from 'react-number-format';
import LoadingSpinner from '../LoadingSpinner';
import { Tooltip } from '../Tooltip';
import WarningIcon from '@material-ui/icons/WarningOutlined';

// Stripe settings are here so they don't reload every state change per stripe's docs.

// This is the public key we will use if an organization isn't using their own stripe account.
let swiftComplyPublicKey = '';
if (process.env.NODE_ENV == "production") {
    swiftComplyPublicKey = 'pk_live_51INgpuGTRIk0TIiskOgfFDxdHjQrRPihntkt7JtWfWcHSq5SaoN4v2h25mWnOPpbN1OcAlG9kyZD9AQk84EJQqOZ00cMrpxIl2';
} else {
    swiftComplyPublicKey = 'pk_test_51INgpuGTRIk0TIiswGPllfbh8s84CGmEIT1IqQXaIUrs6FVHImlM9KOFPxiSnDFcCGgubMxjXPxby45hNTWKgfSb00Iwtzojwf';
}
// This variable is subject to change based on a call when the payments page mounts to check if there is an org setting that has a public key that will override ours.
let keyToUse = swiftComplyPublicKey;
// This is how we connect to stripe's API and it validates our key against the one they have on file.
const stripePromise = loadStripe(keyToUse);
// Default amounts that will be set in config file but will need to be updated here to reflect those amounts in the UI. 
const min_card_payment = 2;
const min_balance_increase = 5;

class Payments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSP: null,
            reportListDropdownVisible: false,
            spListDropdownVisible: false,
            showAllReports: false,
            unpaidReportsList: [],
            spBalance: null,
            allChecked: false,
            paymentList: [],
            subtotal: '0.00',
            paymentDialog: false,
            addToBalance: false,
            paymentSucceeded: null,
            amountToAddToBalance: null,
            callingStripe: false
        };

        // Checking to see if the URL includes our success query meaning we are returning from stripe's payment page. There could be issues with older IE browsers here,
        // if you have another way of finding this in the URL @BrianClapper?
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if ((urlParams.has('success'))) {
            this.state.paymentSucceeded = urlParams.get('success');

            if (urlParams.get('success') === "true") {
                setTimeout(() => {
                    this.handleReturnURL();
                }, 4000)
            }
        }

    }

    componentDidMount = () => {
        const spListReport = '0a3d6a20-46df-47b9-9321-a8cc9a10bb75';
        // Get Service Providers current user is a member of.
        getReportSync(spListReport).then((response) => {
            let serviceProviders = [];
            response.data.rows.forEach((row) => {
                serviceProviders.push({ 'name': getReportValue(response.data, row, 'Name'), 'serviceProviderUUID': getReportValue(response.data, row, 'Service Provider UUID') })
            })
            // If user is only member of one service provider, set as active service provider.
            if (response.data.rows.length === 1) {
                this.setState(() => ({ serviceProviders, activeSP: serviceProviders[0] }), () => {
                    this.getSPBalance();
                    this.updateReport();
                })
            } else {
                this.setState(() => ({ serviceProviders }))
            }
            // Check if the org has their own stripe key. If so we set it as keyToUse.
            getOrganizationSetting('org.payments.stripe.pub_key').then((response) => {
                if (response) {
                    keyToUse = response;
                }
            })
        })
    }

    updateReport = () => {

        // This is where we filter only the reports that have a pending payment status, and to only include reports submitted by the currently selected SP.
        // If only one SP, don't worry about filtering.
        let reportingParams = {
            inputs: {
                'Service Provider UUID': this.state.activeSP ? this.state.activeSP.serviceProviderUUID : ''
            },
            search: {
                'Status': 'pending_payment'
            }
        }

        // This is to include only reports submitted by current user or not. I tried to toggle this between true and false, but the backend filters to only my reports
        // if anything is sent. So we will either include it in the inputs or leave it out alltogether.
        if (!this.state.showAllReports) {
            reportingParams.inputs["my Reports"] = "true"
        }

        // stripe limits us to 50 keys in metadata, which we use to record test reports to pay for, etc.  We reserve 10
        // of these for various other things, leaving us 40 to use for payment items, so limit the report to 40 items
        reportingParams.count = 40;
        getReportSync(
            'e083eb8c-87b0-4f21-9293-dfff9c621f75', reportingParams
        ).then((response) => {
            this.setState(() => ({ unpaidReportsList: [], allChecked: false, subtotal: '0.00', paymentList: [] }), () => {
                response.data.rows.forEach(async (row) => {
                    const price = await this.getSPPrices('Backflow Assembly Test', getReportValue(response.data, row, 'Compliance Report UUID'))

                    // Make sure the equipment and property are fetchable, or an attempt to pay will fail. If errorMsg
                    // gets populated here, this report will show a warning icon/tooltip instead of a payment checkbox.
                    const errorMsg = await this.checkEquipmentAndProperty(getReportValue(response.data, row, 'Compliance Report UUID'));

                    this.setState((prevState) => ({
                        unpaidReportsList: [...prevState.unpaidReportsList, {
                            'Location': (
                                <React.Fragment>
                                    <div>{getReportValue(response.data, row, 'Location Name')}</div>
                                    <div>{getReportValue(response.data, row, 'Address')}</div>
                                    <div>{getReportValue(response.data, row, 'City')}</div>
                                </React.Fragment>
                            ),
                            'Serial Number': getReportValue(response.data, row, 'Serial Number'),
                            'Date of Test': Moment.unix(getReportValue(response.data, row, 'Reported On')).format('YYYY-MM-DD'),
                            'Tester Name': getReportValue(response.data, row, 'Tester Name'),
                            'compliance_report_uuid': getReportValue(response.data, row, 'Compliance Report UUID'),
                            'item_type': 'Backflow Assembly Test',
                            'Price': price,
                            'isChecked': false,
                            'error': errorMsg
                        }]
                    }))
                })
            })
        });
    }

    getReportList = () => {
        let options = [];
        this.state.reports.forEach((report, idx) => {
            options.push(<span key={idx} onClick={() => this.set(idx, 'report')}>{report.name}</span>);
        });
        return options;
    }

    getSPList = () => {
        let options = [];
        this.state.serviceProviders.forEach((serviceProvider, idx) => {
            options.push(<span key={idx} onClick={() => this.setActiveSP(idx)}>{serviceProvider.name}</span>);
        });
        return options;
    }


    toggleReportListDropdown = (action, type) => {
        if (type === 'report') {
            let reportListDropdownVisible = (action === 'close') ? false : !this.state.reportListDropdownVisible;
            this.setState({ reportListDropdownVisible });
        } else if (type === 'sp') {
            let spListDropdownVisible = (action === 'close') ? false : !this.state.spListDropdownVisible;
            this.setState({ spListDropdownVisible });
        }
    }

    setActiveSP = (idx) => {
        this.setState({ activeSP: this.state.serviceProviders[idx], spBalance: null }, () => {
            this.getSPBalance();
            this.updateReport();
        })
    }

    getSPBalance = () => {
        getServiceProviderBalance(this.state.activeSP.serviceProviderUUID).then((response) => {
            this.setState(() => ({ spBalance: (parseInt(response.balance) / 100).toFixed(2) }));
        })
    }

    getSPPrices = async (item_type, compliance_report_uuid) => {
        const price = await getComplianceReportPrice(this.state.activeSP.serviceProviderUUID, item_type, compliance_report_uuid);
        return (parseInt(price.price) / 100).toFixed(2);
    }

    checkEquipmentAndProperty = async (compliance_report_uuid) => {
        let source, source_uuid, property_uuid, equipmentDeleted, propertyDeleted;

        // Get the test report first so we can get the equipment and property. We don't need to check for
        // errors here because if no compliance reports are found they won't get to this function in the
        // first place, and any other errors are handled by the API call.
        ({ source, source_uuid, property_uuid } = await getComplianceReport(compliance_report_uuid));

        // If the getProperty or getEquipment calls fail we'll still need to replace the payment checkboxes
        // with an error message.
        const internalErrorMsg = "There was a problem listing this payment item. If this error persists, " +
            "please contact support@swiftcomply.com and reference the following error code: ";

        // We just need to know if the equipment and/or property were deleted. Note that with a survey the
        // property_uuid will always be populated, so we don't need to check whether source === "property".
        if (property_uuid != "") {
            try {
                await getProperty(property_uuid);
            }
            catch (e) {
                if (e.response.status == 404) {
                    propertyDeleted = true;
                } else {
                    return internalErrorMsg + e.response.headers["x-scc3-request-id"];
                }
            }
        }
        if (source === "equipment") {
            try {
                await getEquipment(source_uuid);
            }
            catch (e) {
                if (e.response.status == 404) {
                    equipmentDeleted = true;
                } else {
                    return internalErrorMsg + e.response.headers["x-scc3-request-id"];
                }
            }
        }

        let whatWasDeleted = "";
        let pronoun = "it has";
        if (propertyDeleted && equipmentDeleted) {
            whatWasDeleted = "location and assembly";
            pronoun = "they have";
        } else if (propertyDeleted) {
            whatWasDeleted = "location";
        } else if (equipmentDeleted) {
            whatWasDeleted = "assembly";
        }

        if (whatWasDeleted !== "") {
            let errorMsg = `We could not look up the ${whatWasDeleted}. This possibly means ${pronoun} been deleted and this report is no longer valid.`;
            errorMsg += " Please contact the city for more information.";
            return errorMsg;
        }
    }

    filterList = (type) => {
        if (type === 'user') {
            this.setState(() => ({ showAllReports: false }), () => {
                this.updateReport();
            })
        } else if (type === 'all') {
            this.setState(() => ({ showAllReports: true }), () => {
                this.updateReport()
            })
        }
    }

    setTable = () => {
        const { unpaidReportsList } = this.state;

        if (unpaidReportsList[0]) {
            return (
                <table id='unpaidReportsTable'>
                    <thead>
                        <tr className="headerRow">
                            <th className="rowLabel">
                                <Checkbox
                                    onChange={() => this.handleCheckInput('all', null)}
                                    checked={this.state.allChecked}
                                    value={this.state.allChecked}
                                    id="allCheck"
                                    color="primary"
                                    size="medium"
                                />
                            </th>
                            {Object.keys(unpaidReportsList[0]).map((column, key) => {
                                if (column !== 'isChecked' && column !== 'compliance_report_uuid' && column !== 'item_type' && column !== 'error') {
                                    return <th key={key} onClick={() => this.handleSortBy(column)} style={{ cursor: 'pointer' }}>{column}</th>
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {unpaidReportsList.map((row, idx) => {
                            return (
                                <tr key={idx}>
                                    <td className="rowLabel">
                                        {row.error ?
                                            <div className="tableItemContainer" style={{ justifyContent: 'center' }}>
                                                <Tooltip
                                                    text={row.error}
                                                    useIcon={<WarningIcon style={{ color: "#FDB600" }} fontSize="large" />}
                                                />
                                            </div>
                                            :
                                            <Checkbox
                                                onChange={() => this.handleCheckInput(null, idx)}
                                                checked={row.isChecked}
                                                value={row.isChecked}
                                                color="primary"
                                                size="medium"
                                            />
                                        }
                                    </td>
                                    {Object.keys(unpaidReportsList[0]).map((column, key) => {
                                        if (column !== 'isChecked' && column !== 'compliance_report_uuid' && column !== 'item_type' && column !== 'error') {
                                            if (column === 'Price') {
                                                return <td key={column}>{`$ ${unpaidReportsList[idx][column]}`}</td>
                                            } else {
                                                return <td key={column}>{unpaidReportsList[idx][column]}</td>
                                            }
                                        }
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }
    }

    handleCheckInput = (type, idx) => {
        let unpaidReportsList = [...this.state.unpaidReportsList];

        if (type === 'all') {
            this.setState((prevState) => ({ allChecked: !prevState.allChecked }), () => {
                for (let i = 0; i < unpaidReportsList.length; i++) {
                    if (!unpaidReportsList[i].error) {
                        unpaidReportsList[i] = { ...unpaidReportsList[i], isChecked: this.state.allChecked }
                    }
                }
            })
        } else {
            unpaidReportsList[idx] = { ...unpaidReportsList[idx], isChecked: !this.state.unpaidReportsList[idx].isChecked }
            if (unpaidReportsList.filter(e => { return e.isChecked === true || e.error; }).length === unpaidReportsList.length) {
                this.setState({ allChecked: true })
            } else {
                this.setState({ allChecked: false })
            }
        }
        this.setState({ unpaidReportsList }, () => {
            this.getTotals('subtotal')
        })
    }

    getTotals = (type) => {
        const { unpaidReportsList, subtotal, spBalance } = this.state;
        if (type === 'subtotal') {
            let prices = [];
            let paymentList = []
            for (let i = 0; i < unpaidReportsList.length; i++) {
                if (unpaidReportsList[i].isChecked) {
                    prices.push(parseFloat(unpaidReportsList[i].Price));
                    paymentList.push({
                        item_type: 'compliance report',
                        compliance_report_uuid: unpaidReportsList[i].compliance_report_uuid
                    })
                }
            }
            const subtotal = prices.reduce((a, b) => a + b, 0).toFixed(2)
            this.setState({ paymentList, subtotal })
        } else if (type === 'total') {
            if ((subtotal - spBalance) <= 0) {
                return '0.00';
            } else {
                return (subtotal - spBalance).toFixed(2);
            }
        }
    }

    togglePaymentDialog = (type) => {

        if (type === 'balance') {
            this.setState((prevState) => ({ paymentDialog: !prevState.paymentDialog, addToBalance: true }));
        } else if (type === 'closeBalance') {
            this.setState((prevState) => ({ paymentDialog: !prevState.paymentDialog }), () => {
                setTimeout(() => {
                    this.setState({ addToBalance: false, amountToAddToBalance: null })
                }, 500)
            });
        } else {
            this.setState((prevState) => ({ paymentDialog: !prevState.paymentDialog }));
        }
    }

    initiatePayment = (type) => {
        const { activeSP, paymentList, subtotal, spBalance } = this.state;

        let payload = {
            service_provider_uuid: activeSP.serviceProviderUUID,
            success_url: `${window.location.href}?success=true`,
            cancel_url: `${window.location.href}?success=false`,
            payment_items: paymentList
        }

        if (type === 'initial') {
            // If the sum of the selected reports is less than the available balance, toggle the dialog confirming they want to pay
            // from balance. Otherwise proceed with stripe call.
            if ((subtotal - spBalance) <= 0) {
                this.togglePaymentDialog();
            } else {
                this.stripeCall(payload);
            }
        } else if (type === 'payWithBalance') {

            initiatePaymentProcess(payload).then(() => {
                this.togglePaymentDialog();
                this.updateReport();
                this.getSPBalance();
            });
        } else if (type === 'addToBalance') {

            payload = {
                service_provider_uuid: activeSP.serviceProviderUUID,
                success_url: `${window.location.href}?success=true`,
                cancel_url: `${window.location.href}?success=false`,
                payment_items: [{
                    item_type: "balance",
                    amount: this.state.amountToAddToBalance * 100
                }]
            }

            this.stripeCall(payload);
        }

    }

    stripeCall = async (payload) => {

        this.setState({ callingStripe: true });
        // These are the api calls needed to send the necessary information to the payments service and to stripe in order.
        const stripe = await stripePromise;
        const session = await initiatePaymentProcess(payload);
        const result = await stripe.redirectToCheckout({ sessionId: session.session_id });
        this.setState({ callingStripe: false });

        // We have the ability here to possibly show an error message if latency or connection error prevents being transferred to stripe,
        // by analyzing what comes back from "result". 
    }

    handleReturnURL = () => {
        updateURL('/payments', {});
        this.setState(() => ({ paymentSucceeded: null }), () => {
            this.updateReport();
            if (this.state.activeSP) {
                this.getSPBalance();
            }
        });
    }

    setAmountToAdd = (value) => {
        this.setState(() => ({ amountToAddToBalance: value.floatValue }));
    }

    handleSortBy = (type) => {
        const copiedReportsList = [...this.state.unpaidReportsList];

        if (type === 'Tester Name' || type === 'Serial Number' || type === 'Price') {
            const sortedList = copiedReportsList.sort((a, b) => {
                return (a[type] > b[type]) ? 1 : -1
            });

            this.setState({ unpaidReportsList: sortedList })
        } else if (type === 'Date of Test') {
            const sortedList = copiedReportsList.sort((a, b) => {
                return (Moment(a[type]).valueOf() > Moment(b[type]).valueOf()) ? 1 : -1
            });

            this.setState({ unpaidReportsList: sortedList });
        }

    }

    render() {

        const { spBalance, reportListDropdownVisible, showAllReports, activeSP, spListDropdownVisible,
            unpaidReportsList, paymentList, paymentDialog, addToBalance, paymentSucceeded, amountToAddToBalance, subtotal,
            callingStripe } = this.state;

        let balanceColor;
        if (spBalance === 0) {
            balanceColor = '#FDB600'
        } else if (spBalance < 0) {
            balanceColor = '#FF005C'
        } else {
            balanceColor = '#00D89D'
        }

        return (
            <React.Fragment>
                <Dialog
                    open={paymentDialog ? true : false}
                    onClose={this.togglePaymentDialog}
                    aria-labelledby="form-dialog-title"
                    id="paymentsDialog"
                >
                    {!addToBalance &&
                        <React.Fragment>
                            <DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">{`You are about to pay for (${this.state.paymentList.length}) selected reports with available balance. Proceed?`}</DialogTitle>
                            <DialogActions>
                                <button className="medButtonPrimary" onClick={() => this.initiatePayment('payWithBalance')}>Yes</button>
                                <button className="medButtonSecondary" onClick={() => this.togglePaymentDialog('close')}>Cancel</button>
                            </DialogActions>
                        </React.Fragment>
                    }
                    {addToBalance &&
                        <React.Fragment>
                            <DialogTitle id="form-dialog-title">
                                <div className="flexCenteredColumn">
                                    <div>Select an amount to add to your balance.</div>
                                    <div style={{ fontSize: "1.2rem" }}>{`Minimum $${min_balance_increase}`}</div>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div className="flexJustifyCenter addBalanceInput">
                                    <NumberFormat value={amountToAddToBalance} decimalScale={2} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} id="addToBalanceInput" onValueChange={(values) => this.setAmountToAdd(values)} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <button className="medButtonPrimary" onClick={() => this.initiatePayment('addToBalance')} disabled={callingStripe || amountToAddToBalance < min_balance_increase}>{callingStripe ? 'Please Wait' : 'Add'}</button>
                                <button className="medButtonSecondary" onClick={() => this.togglePaymentDialog('closeBalance')}>Cancel</button>
                            </DialogActions>
                        </React.Fragment>
                    }
                </Dialog>
                <Dialog
                    open={paymentSucceeded ? true : false}
                    onClose={this.handleReturnURL}
                    aria-labelledby="form-dialog-title"
                    id="paymentsDialog"
                >
                    {paymentSucceeded &&
                        <DialogTitle>
                            {paymentSucceeded === "true" &&
                                <React.Fragment>
                                    <span style={{ margin: '4rem', textAlign: 'center' }} className="flexJustifyCenter">Payment Succeeded. Please wait while we update a few things...</span>
                                    <LoadingSpinner />
                                </React.Fragment>
                            }
                            {paymentSucceeded === "false" &&
                                <span style={{ margin: '4rem' }}>Payment Cancelled</span>
                            }
                        </DialogTitle>
                    }
                    {paymentSucceeded === "false" && <DialogActions>
                        <button className="medButtonPrimary" onClick={() => this.handleReturnURL()}>Ok</button>
                    </DialogActions>
                    }
                </Dialog>
                <div className="componentContainer" id="payments">
                    <div className="componentHeader">
                        <div className="flexAlignCenter">
                            <CreditCardIcon style={{ fontSize: '2rem', marginRight: '1rem' }} />
                            <h3>Payments</h3>
                        </div>
                    </div>
                    <div className="tableButtonBankContainer">
                        <div className="flexAlignCenter">
                            <ClickAwayListener onClickAway={() => this.toggleReportListDropdown('close', 'sp')}>
                                <div className="dropdown" onClick={() => this.toggleReportListDropdown('toggle', 'sp')} role=''>
                                    <button className={reportListDropdownVisible ? 'longButtonSecondary openBorderRadius' : 'longButtonSecondary'}>
                                        {(activeSP !== null) ? activeSP.name : "Select a Service Provider"}
                                        <img src={dropdown} alt='dropdown' />
                                    </button>
                                    {spListDropdownVisible && (
                                        <div className="dropdown__content" id="dropdown">
                                            {this.getSPList()}
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                            {activeSP &&
                                <ClickAwayListener onClickAway={() => this.toggleReportListDropdown('close', 'report')}>
                                    <div className="dropdown" onClick={() => this.toggleReportListDropdown('toggle', 'report')} role=''>
                                        <button className={reportListDropdownVisible ? 'longButtonSecondary openBorderRadius' : 'longButtonSecondary'}>
                                            {showAllReports ? 'All Test Reports' : 'My Test Reports'}
                                            <img src={dropdown} alt='dropdown' />
                                        </button>
                                        {reportListDropdownVisible && (
                                            <div className="dropdown__content" id="dropdown">
                                                <span onClick={() => this.filterList('user')}>My Test Reports</span>
                                                <span onClick={() => this.filterList('all')}>All Test Reports</span>
                                            </div>
                                        )}
                                    </div>
                                </ClickAwayListener>
                            }
                            {spBalance &&
                                <React.Fragment>
                                    <span className="spBalance">Balance: $<span style={{ color: balanceColor }} className="spBalance__amount">{spBalance}</span></span>
                                    <button className="xsmallButtonPrimary" style={{ marginLeft: '-4rem' }} onClick={() => this.togglePaymentDialog('balance')}><AddIcon fontSize="large" /></button>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    {activeSP &&
                        <div className="mainTableContainer">
                            {this.setTable()}
                            {unpaidReportsList.length > 0 &&
                                <div className="totals">
                                    <div className="totals__top">
                                        <div>
                                            <span>Subtotal {paymentList.length !== 0 && <span>{`(${paymentList.length} ${paymentList.length > 1 ? 'items' : 'item'})`}</span>}: </span><span>{`$ ${this.state.subtotal}`}</span>
                                        </div>
                                        {paymentList.length !== 0 &&
                                            <React.Fragment>
                                                <div>
                                                    <span>Balance Available: </span><span>{`$ ${spBalance}`}</span>
                                                </div>
                                                {subtotal - spBalance > 0 &&
                                                    <div className="totals__total">
                                                        <span>Total Due:</span><span>{` $ ${this.getTotals('total')}`}</span>
                                                    </div>
                                                }
                                                {subtotal - spBalance <= 0 &&
                                                    <div className="totals__total">
                                                        <span>Total Due From Balance:</span><span>{` $ ${subtotal}`}</span>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                    {paymentList.length !== 0 &&
                                        <div className="totals__bottom">
                                            <div>
                                                {subtotal - spBalance <= 0 && <button className="longButtonPrimary" onClick={() => this.initiatePayment('initial')} disabled={paymentList.length === 0}>Pay with Balance</button>}
                                                {subtotal - spBalance > 0 && <button className="longButtonPrimary" onClick={() => this.initiatePayment('initial')} disabled={callingStripe || subtotal - spBalance < min_card_payment}>{callingStripe ? 'Please Wait' : 'Pay Now'}</button>}
                                            </div>
                                            {(subtotal - spBalance) > 0 && (subtotal - spBalance) < min_card_payment &&
                                                <span>{`Minimum of $${min_card_payment} required. Please add/remove an item or add to your balance.`}</span>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    {activeSP && unpaidReportsList.length === 0 &&
                        <div className="flexJustifyCenter">No Unpaid Reports Found</div>
                    }
                    <CreditCardIcon className="dashboardIconLarge dashboardIconLarge__adjusted" />
                </div>
            </React.Fragment>
        )
    }
}

export default Payments;
