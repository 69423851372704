/* eslint-disable react/prop-types */
import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PrivateRoute } from "./PrivateRoute";
import { HelperRoute } from "./HelperRoute";
import AssembliesDashboard from "../components/Assemblies/AssembliesDashboard";
import CommsDashboard from "../components/Communications/CommsDashboard";
import ContactsDashboard from "../components/Contacts/ContactsDashboard";
import Dashboard from "../components/Dashboard/Dashboard";
import { default as CommonDashboard } from "../components/Common/Dashboard/Dashboard";
import DataAnalysisDashboard from "../components/DataAnalysis/DataAnalysisDashboard";
import ForgotPassword from "../components/ForgotPassword";
import FormBuilderDashboard from "../components/FormBuilder/FormBuilderDashboard";
import Header from "../components/Header";
import HelpDashboard from "../components/Help/HelpDashboard";
import LocationsDashboard from "../components/Locations/LocationsDashboard";
import Login from "../components/Login";
import ModalDuplicateCheck from "../components/ModalDuplicateCheck";
import ModalDuplicateContext from "../contexts/ModalDuplicateContext";
import ModalError from "../components/ModalError";
import NotesDashboard from "../components/Notes/NotesDashboard";
import OrgDashboard from "../components/Settings/OrgDashboard";
import ReportingDashboard from "../components/Reporting/ReportingDashboard";
import ServiceProvidersDashboard from "../components/ServiceProviders/ServiceProvidersDashboard";
import Sidebar from "../components/Sidebar";
import SurveysDashboard from "../components/Surveys/SurveysDashboard";
import SurveysWrapper from "../components/Surveys/SurveyWrapper";
import TestKitDashboard from "../components/ServiceProviders/TestKitDashboard";
import TestReportWrapper from "../components/TestReports/TestReportWrapper";
import TestReportsDashboard from "../components/TestReports/TestReportsDashboard";
import UserProfile from "../components/UserProfile/UserProfile";
import UsersDashboard from "../components/Users/UsersDashboard";
import StateReportsDashboard from "../components/StateReports/StateReportsDashboard";
import Payments from "../components/Payments/Payments";
import NotificationsDashboard from "../components/Notifications/NotificationsDashboard";
import LocationsHome from "../components/Backflow/Locations/LocationsHome";
import IndustrialUsersHome from "../components/Pretreatment/IndustrialUsers/IndustrialUsersHome";
import ViewProperty from "../components/Common/Properties/ViewProperty";
import ContactsHome from "../components/Common/Contacts/ContactsHome";
import OutfallsHome from "../components/Pretreatment/Outfalls/OutfallsHome";
import AddProperty from "../components/Common/Properties/AddProperty";
import AddContact from "../components/Common/Contacts/AddContact";
import ViewContact from "../components/Common/Contacts/ViewContact";
import ViewOutfall from "../components/Pretreatment/Outfalls/ViewOutfall";
import AddOutfall from "../components/Pretreatment/Outfalls/AddOutfall";
import ViewLimit from "../components/Pretreatment/Limits/ViewLimit";
import AddLimit from "../components/Pretreatment/Limits/AddLimit";
import DataTables from "../components/Common/DataTables";
import ViewSampleReportConfig from "../components/Pretreatment/SampleReportConfigs/ViewSampleReportConfig";
import AddSampleReportConfig from "../components/Pretreatment/SampleReportConfigs/AddSampleReportConfig";
import ViewSamplingConfig from "../components/Pretreatment/SamplingConfigs/ViewSamplingConfig";
import AddSamplingConfig from "../components/Pretreatment/SamplingConfigs/AddSamplingConfig";
import PermitsHome from "../components/Pretreatment/Permits/PermitsHome";
import AddPermit from "../components/Pretreatment/Permits/AddPermit";
import Configuration from "../components/Common/Configuration/Configuration";
import TemplatesHome from "../components/Common/Templates/TemplatesHome";
import EditTemplate from "../components/Common/Templates/EditTemplate";
import ViewPermit from "../components/Pretreatment/Permits/ViewPermit";
import GeneralControlsHome from "../components/Pretreatment/GeneralControls/GeneralControlsHome";
import AddGeneralControl from "../components/Pretreatment/GeneralControls/AddGeneralControl";
import ViewGeneralControl from "../components/Pretreatment/GeneralControls/ViewGeneralControl";
import ViewSampleReport from "../components/Pretreatment/SampleReports/ViewSampleReport";

export const history = createBrowserHistory();

// prettier-ignore
const AppRouter = () =>
	<React.Fragment>
		<ModalError />
		<ModalDuplicateContext>
			<Router history={history}>
				<Switch>
					<Route path="/" exact component={Login} />
					<Route path="/forgot-password" component={ForgotPassword} />

					{/* New Backflow/common components */}
					<Route sensitive path="/bf/dashboard" component={(props) => <CommonDashboard {...props} />} />
					<Route sensitive path="/bf/lo/addLocation" component={(props) => <AddProperty {...props} />} />
					<Route sensitive path="/bf/lo/:id/c/:id" component={(props) => <ViewContact {...props} />} />
					<Route sensitive path="/bf/lo/:id/addContact" component={(props) => <AddContact {...props} />} />
					<Route sensitive path="/bf/lo/:id" component={(props) => <ViewProperty {...props} />} />
					<Route sensitive path="/bf/lo" component={(props) => <LocationsHome {...props} />} />
					<Route sensitive path="/bf/c/addContact" component={(props) => <AddContact {...props} />} />
					<Route sensitive path="/bf/c/:id" component={(props) => <ViewContact {...props} />} />
					<Route sensitive path="/bf/c" component={(props) => <ContactsHome {...props} />} />
					<Route sensitive path="/bf/datatables" component={(props) => <DataTables {...props} />} />

					{/* Pretreatment components */}
					<Route sensitive path="/pt/dashboard" component={(props) => <CommonDashboard {...props} />} />
					<Route sensitive path="/pt/iu/addIU" component={(props) => <AddProperty {...props} />} />
					<Route sensitive path="/pt/iu/:id/o/:id/src/:id/sc/:id" component={(props) => <ViewSamplingConfig {...props} />} />
					<Route sensitive path="/pt/iu/:id/o/:id/src/:id/addSamplingConfig" component={(props) => <AddSamplingConfig {...props} />} />
					<Route sensitive path="/pt/iu/:id/o/:id/src/:id" component={(props) => <ViewSampleReportConfig {...props} />} />
					<Route sensitive path="/pt/iu/:id/o/:id/addSampleReportConfig" component={(props) => <AddSampleReportConfig {...props} />} />
					<Route sensitive path="/pt/iu/:id/o/:id/l/:id" component={(props) => <ViewLimit {...props} />} />
					<Route sensitive path="/pt/iu/:id/o/:id/addLimit" component={(props) => <AddLimit {...props} />} />
					<Route sensitive path="/pt/iu/:id/o/:id" component={(props) => <ViewOutfall {...props} />} />
					<Route sensitive path="/pt/iu/:id/addOutfall" component={(props) => <AddOutfall {...props} />} />
					<Route sensitive path="/pt/iu/:id/c/:id" component={(props) => <ViewContact {...props} />} />
					<Route sensitive path="/pt/iu/:id/sr/:id" component={(props) => <ViewSampleReport {...props} />} />
					<Route sensitive path="/pt/iu/:id/addContact" component={(props) => <AddContact {...props} />} />
					<Route sensitive path="/pt/iu/:id" component={(props) => <ViewProperty {...props} />} />
					<Route sensitive path="/pt/iu" component={(props) => <IndustrialUsersHome {...props} />} />
					<Route sensitive path="/pt/p/addPermit" component={(props) => <AddPermit {...props} />} />
					<Route sensitive path="/pt/p/:id" component={(props) => <ViewPermit {...props} />} />
					<Route sensitive path="/pt/p" component={(props) => <PermitsHome {...props} />} />
					<Route sensitive path="/pt/o/:id/src/:id/sc/:id" component={(props) => <ViewSamplingConfig {...props} />} />
					<Route sensitive path="/pt/o/:id/src/:id/addSamplingConfig" component={(props) => <AddSamplingConfig {...props} />} />
					<Route sensitive path="/pt/o/:id/src/:id" component={(props) => <ViewSampleReportConfig {...props} />} />
					<Route sensitive path="/pt/o/:id/addSampleReportConfig" component={(props) => <AddSampleReportConfig {...props} />} />
					<Route sensitive path="/pt/o/:id/l/:id" component={(props) => <ViewLimit {...props} />} />
					<Route sensitive path="/pt/o/:id/addLimit" component={(props) => <AddLimit {...props} />} />
					<Route sensitive path="/pt/o/:id" component={(props) => <ViewOutfall {...props} />} />
					<Route sensitive path="/pt/o" component={(props) => <OutfallsHome {...props} />} />
					<Route sensitive path="/pt/c/addContact" component={(props) => <AddContact {...props} />} />
					<Route sensitive path="/pt/c/:id" component={(props) => <ViewContact {...props} />} />
					<Route sensitive path="/pt/c" component={(props) => <ContactsHome {...props} />} />
					<Route sensitive path="/pt/cfg/t/addTemplate" component={(props) => <EditTemplate {...props} />} />
					<Route sensitive path="/pt/cfg/t/:id" component={(props) => <EditTemplate {...props} />} />
					<Route sensitive path="/pt/cfg/t" component={(props) => <TemplatesHome {...props} />} />
					<Route sensitive path="/pt/cfg/gc/addGeneralControl" component={(props) => <AddGeneralControl {...props} />} />
					<Route sensitive path="/pt/cfg/gc/:id" component={(props) => <ViewGeneralControl {...props} />} />
					<Route sensitive path="/pt/cfg/gc" component={(props) => <GeneralControlsHome {...props} />} />
					<Route sensitive path="/pt/cfg" component={(props) => <Configuration {...props} />} />
					<Route sensitive path="/pt/datatables" component={(props) => <DataTables {...props} />} />

					{/* Original Backflow components */}
					<div className="flex maxHeight">
						<HelperRoute component={Header} />
						<HelperRoute component={Sidebar} />
						<div className="mainContent">
							<Switch>
								<PrivateRoute path="/dashboard" component={(props) => <Dashboard {...props} />} />
								<PrivateRoute path="/formBuilder" exact component={(props) => <FormBuilderDashboard {...props} />} />
								<PrivateRoute path="/users" component={(props) => <UsersDashboard {...props} />} />
								<PrivateRoute path="/locations" component={(props) => <LocationsDashboard {...props} />} />
								<PrivateRoute path="/contacts" component={(props) => <ContactsDashboard {...props} />} />
								<PrivateRoute path="/assemblies" component={(props) => <AssembliesDashboard {...props} />} />
								<PrivateRoute path="/serviceProviders" component={(props) => <ServiceProvidersDashboard {...props} />} />
								<PrivateRoute path="/serviceProvidersEquipment" component={(props) => <TestKitDashboard {...props} />} />
								<PrivateRoute path="/testReports/newTestReport" component={(props) => <TestReportWrapper {...props} />} />
								<PrivateRoute path="/payments" component={(props) => <Payments {...props} />} />
								<PrivateRoute path="/testReports" component={(props) => <TestReportsDashboard {...props} />} />
								<PrivateRoute path="/surveys/newSurvey" component={(props) => <SurveysWrapper {...props} />} />
								<PrivateRoute path="/surveys" component={(props) => <SurveysDashboard {...props} />} />
								<PrivateRoute path="/dataAnalysis" exact component={(props) => <DataAnalysisDashboard {...props} />} />
								<PrivateRoute path="/forms" exact component={(props) => <FormBuilderDashboard {...props} />} />
								<PrivateRoute path="/help" exact component={(props) => <HelpDashboard {...props} />} />
								<PrivateRoute path="/communications" component={(props) => <CommsDashboard {...props} />} />
								<PrivateRoute path="/notes" exact component={(props) => <NotesDashboard {...props} />} />
								<PrivateRoute path="/profile" exact component={(props) => <UserProfile {...props} />} />
								<PrivateRoute path="/organization" exact component={(props) => <OrgDashboard {...props} />} />
								<PrivateRoute path="/reporting" component={(props) => <ReportingDashboard {...props} />} />
								<PrivateRoute path="/stateReports" component={(props) => <StateReportsDashboard {...props} />} />
								<PrivateRoute path="/notifications" exact component={(props) => <NotificationsDashboard {...props} />} />
							</Switch>
						</div>
						<ModalDuplicateCheck />
					</div>
				</Switch>
			</Router>
		</ModalDuplicateContext>
	</React.Fragment>

export default AppRouter;
