import React, { useEffect, useState, useRef } from "react";

import { getReportRowCount } from '../../api/reportingAPI';

import LastPageIcon from '@material-ui/icons/SkipNext';
import FirstPageIcon from '@material-ui/icons/SkipPrevious';
import PrevPageIcon from '@material-ui/icons/NavigateBefore';
import NextPageIcon from '@material-ui/icons/NavigateNext';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

const ReportTablePagination = (props) => {
    const dropdownRef = useRef(null);
    const fetchData = props.fetchData;
    const totalRows = props.totalRowCount
    const [page, setPage] = useState(() => props.page)

    const [pageSize, setPageSize] = useState(() => {
        const initialCount = props.pageRowCount;
        return initialCount;
    });
    
    const pageSizeOptions = [
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" },
    ];

    useEffect(
        () => {
            setPage(props.page)
        },
        [props.page]
    )

    useEffect(
        () => fetchData(page, pageSize),
        [page, pageSize]
    )

    useEffect(
        () => {
            setPage(1)
            setPageSize(props.getReportingParams().count)
        },
        [props.filterJSON, props.searchJSON]
    )

    const handleChangePageSize = (event) => {
        setPageSize(parseInt(event.target.value));
        /* 
        When the pageSize changes, the selected page may be outside of the 
        possible range of pages. Rather than try to compute a new selected 
        page (what would it even be?), it seems sensible to simply reset to 
        the first page, which is always valid. 
         */
        setPage(1); 
        dropdownRef.current.blur()
    }

    return (
        totalRows && totalRows > 0 ? (
        <div className="new-table-pagination-controls">
            <div className="flexAlignCenter"> 
                <span>Rows per page:&nbsp;</span>
                <Select 
                    inputRef={dropdownRef}
                    native
                    size="small"
                    value={pageSize}
                    input={<Input sx={{fontSize: '1.6rem'}} />}
                    onChange={handleChangePageSize}
                    data-testid="paginationRowsPerPage"
                    id="paginationRowsPerPage" //Work-around for unexplained Playwright behavior. See comment in test "Pagination no unnecessary row count queries: Locations" 
                >
                    {pageSizeOptions.map((option) => (
                        // <MenuItem value={option.value}>{option.label}</MenuItem>
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                </Select>
            </div> 
            {/* inline style {flex: "none"} required to make this div float all the way right */}
            <div className="flexAlignCenter" style={{flex: "none"}} data-testid="paginationNav">
                <FirstPageIcon style={{cursor: "pointer"}} fontSize="large" onClick={() => setPage(1)} data-testid="paginationNavFirst"/>
                <PrevPageIcon style={{cursor: "pointer"}} fontSize="large" onClick={() => setPage(Math.max(1, page-1))} data-testid="paginationNavPrev"/>
                <span>page {page} of {totalRows ? Math.ceil(totalRows / pageSize) : "??"}</span>
                <NextPageIcon style={{cursor: "pointer"}} fontSize="large" onClick={() => setPage(Math.min(page+1, Math.ceil(totalRows / pageSize))) } data-testid="paginationNavNext"/>
                <LastPageIcon style={{cursor: "pointer"}} fontSize="large" onClick={() => setPage(Math.ceil(totalRows / pageSize)) } data-testid="paginationNavLast"/>
            </div>
            <div data-testid="paginationTotalRowInfo">
                <span>&nbsp;{(page -1) * pageSize + 1}-{Math.min(page * pageSize, totalRows)} of {totalRows ? totalRows : "??"} items</span>
            </div>
        </div>
    ) : ( totalRows === undefined && (
        <div className="new-table-pagination-controls">
           <div className="flexAlignCenter"> 
                <span>Rows per page:&nbsp;</span>
                <Select 
                    inputRef={dropdownRef}
                    native
                    size="small"
                    value={pageSize}
                    input={<Input sx={{fontSize: '1.6rem'}} />}
                    onChange={handleChangePageSize}
                    data-testid="paginationRowsPerPage"
                    id="paginationRowsPerPage" //Work-around for unexplained Playwright behavior. See comment in test "Pagination no unnecessary row count queries: Locations" 
                >
                    {pageSizeOptions.map((option) => (
                        // <MenuItem value={option.value}>{option.label}</MenuItem>
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                </Select>
            </div> 
            <div className="flexAlignCenter" style={{flex: "none"}} data-testid="paginationNav">
                <PrevPageIcon style={{cursor: page > 1 ? "pointer" : "not-allowed"}} fontSize="large" onClick={page > 1 ? () => setPage(Math.max(0, props.page - 1)) : null} data-testid="paginationNavPrev"/>
                <span>page {page}</span>
                <NextPageIcon style={{cursor: props.nextPage && props.nextPage > page ? "pointer" : "not-allowed"}} fontSize="large" onClick={props.nextPage && props.nextPage > page ? () => setPage(props.nextPage ? props.nextPage : props.page) : null } data-testid="paginationNavNext" disabled={! props.nextPage || props.nextPage <= page}/>
            </div>
            <div data-testid="paginationTotalRowInfo">
                <span>&nbsp;</span>
            </div>
        </div>
    ))
);

}

export default ReportTablePagination;


