import React from 'react';
import bb, { bar, gauge, donut, pie } from "billboard.js";
import "billboard.js/dist/billboard.css";
import { getReportSync } from '../../api/reportingAPI';
import { setReportingParams, parseReportingParams } from '../../utils/reporting';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableInputsFilter from '../TableInputsFilter';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '../Tooltip';
import { getReportsList, filterCounter, getReportsOptions } from './WidgetReportsUtil';


class ChartWidget extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			reportsList: null,
			reportData: null,
			reportingParams: {},
			widgetEditMode: false,
			title: '',
			chart: null,
			chartType: null
		};

		let properties;
		properties = JSON.parse(this.props.properties)
		this.state.properties = properties
		this.state.reportingParams = properties && properties.reportingParams ? properties.reportingParams : parseReportingParams();
		this.inputFiltersRef = React.createRef();

	}

	componentDidMount() {
		if (!this.props.staged) {
			this.generateChart('initial');
		} else {
			this.setState({ widgetEditMode: true });
			this.props.toggleDraggablePause('on')
		}
		getReportsList('chart').then((response) => {
			this.setState(() => ({ reportsList: response }));
		})
		this.getWidgetData();
	}

	componentDidUpdate = (prevProps) => {
		if (this.state.chart && !this.props.staged && !this.state.widgetEditMode) {
			if (this.props.size !== prevProps.size) {
				this.state.chart.resize({
					height: this.props.size.height,
					width: this.props.size.width
				})
			}
		}
		if (this.props.properties !== prevProps.properties) {
			this.setState(() => ({ properties: JSON.parse(this.props.properties) }))
		}

		if (prevProps.staged !== this.props.staged && this.state.reportData) {
			this.generateChart('initial')
			setTimeout(() => {
				this.getWidgetData(JSON.parse(this.props.properties).reportingParams)
			}, 200)
		}
	}

	getWidgetData = async (newReportingParams) => {
		const { properties } = this.state;
		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : properties.reportingParams;

		getReportSync(properties.report_uuid, reportingParams).then((response) => {
			this.setState(() => ({ reportData: response.data, title: this.props.title }), () => {
				if (!this.props.staged) {
					this.generateChart('load')
				}
			})
		});
	}

	generateChart = (generateType) => {
		const properties = JSON.parse(this.props.properties)
		let columnData = [];
		let barColumnData = []; // bar charts are slightly different in their layout
		let categories = [];

		if (this.state.reportData) {
			for (let i = 1; i < this.state.reportData.outputs.length; i++) {
				barColumnData[i - 1] = [this.state.reportData.outputs[i].name];
			}
		}

		// bar charts come from the backend as something like group label, value label, value label, ...
		// pie charts are label, count
		// so we need to convert that to what C3 wants which is slightly different for each type not only with the values
		// but also the labeling
		if (this.state.reportData && this.state.reportData.rows) {
			this.state.reportData.rows.forEach((row) => {
				categories.push(row.values[0]);
				if (this.state.properties.chartType === 'bar') {
					for (let i = 1; i < row.values.length; i++) {
						barColumnData[i - 1].push(row.values[i]);
					}
				} else if (properties.chartType === 'gauge') {
					columnData.push(this.props.title, parseInt(row.values[0]));
				} else {
					columnData.push(row.values);
				}
			});
		}

		let type, columns, legend, padding, axis;
		if (properties.chartType === 'bar') {
			axis = {
				x: {
					type: 'category',
					categories: categories,
					tick: {
						centered: true
					}
				},
				y: {
					tick: {
						format: function (x) { return x % 1 === 0 ? x : null; }
					},
					label: {
						position: 'outer-middle'
					},
					padding: {
						top: 0,
						bottom: 0
					}
				}
			},
				padding = {
					left: 70,
					right: 70,
					top: 50,
					bottom: 10
				},
				legend = {
					show: true
				},
				columns = barColumnData,
				type = bar()
		} else if (properties.chartType === 'pie') {
			padding = {
				left: 0,
				right: 0,
				top: 40,
				bottom: 0
			},
				legend = {
					show: true
				},
				columns = columnData,
				type = pie()
		} else if (properties.chartType === 'donut') {
			padding = {
				left: 0,
				right: 0,
				top: 40,
				bottom: 0
			},
				columns = columnData,
				type = donut()
		} else if (properties.chartType === 'gauge') {
			padding = {
				left: 0,
				right: 0,
				top: 30,
				bottom: 40
			},
				legend = {
					show: false
				},
				columns = [columnData],
				type = gauge()
		} else {
			columns = [],
				type = donut()
		}

		if (generateType === 'initial') {
			this.setState(() => ({
				chart:
					bb.generate({
						bindto: `#widget__chart-${this.props.position}`,
						data: {
							columns: type === gauge() ?
								[["data0", 0]] : [],
							type: type
						},
						size: {
							height: 298
						},
						legend: legend,
						padding: padding,
						axis: axis,
						color: {
							pattern: [
								'#345A5E',
								'#B8860B',
								'#1F3A93',
								'#2A150D',
								'#7928A1',
								'#2E343B'
							]
						},
						transition: { duration: 0 }
					})
			}))
		} else if (generateType === 'load') {
			if (this.state.chart && !this.props.staged) {
				this.state.chart.destroy()
				this.setState(() => ({
					chart:
						bb.generate({
							bindto: `#widget__chart-${this.props.position}`,
							data: {
								columns: columns,
								type: type
							},
							size: {
								height: this.props.size.height,
								width: this.props.size.width
							},
							legend: legend,
							padding: padding,
							axis: axis,
							color: {
								pattern: [
									'#345A5E',
									'#B8860B',
									'#1F3A93',
									'#2A150D',
									'#7928A1',
									'#2E343B'
								]
							},
							transition: { duration: 0 }
						})
				}))
				this.setState(() => ({ chartType: properties.chartType, title: this.state.title }))
			}
		}
	}

	addWidget = (updateType) => {
		const { properties } = this.state;
		let skipUpdate = false;

		if (updateType === 'reportChange') {
			skipUpdate = true;
		}

		if (!this.props.staged) {
			this.props.updateWidget({
				"properties": JSON.stringify({ ...this.state.properties, chartType: this.state.chartType, reportingParams: this.setFilters(this.inputFiltersRef.current.state.values, skipUpdate) }),
				"title": this.state.title
			},
				this.props.dashboard_widget_uuid, this.props.position
			).then(() => {
				if (updateType !== 'reportChange') {
					this.handleClose()
				}
			})
		} else {
			this.props.addWidget({
				...this.state.reportData,
				title: this.state.title,
				coords: this.props.layout[this.props.position],
				chartType: this.state.chartType,
				reportingParams: this.setFilters(this.inputFiltersRef.current.state.values)
			}, 'create')
			this.generateChart('load')
			this.handleClose()
		}
	}

	handleClose = () => {
		this.setState(() => ({ widgetEditMode: false }))
		this.props.toggleDraggablePause('off')
	}

	cancelEdit = () => {
		if (this.props.staged) {
			this.props.addWidget('cancel')
		}
		this.handleClose();
		this.getWidgetData();
	}

	toggleWidgetEditMode = () => {
		this.setState((prevState) => ({ widgetEditMode: !prevState.widgetEditMode }));
		this.props.toggleDraggablePause('on');
	}

	onTitleChange = (value) => {
		this.setState(() => ({ title: value }));
	}

	setChartType = (value) => {
		this.setState(() => ({ chartType: value }))
	}

	setFilters = (filters, skipUpdate) => {
		let newReportingParams = setReportingParams(
			JSON.parse(this.props.properties).reportingParams ? JSON.parse(this.props.properties).reportingParams : this.state.reportingParams,
			{ filters },
			false
		);
		if (skipUpdate === false) {
			this.getWidgetData(newReportingParams);
		}
		return newReportingParams
	}

	deleteWidget = () => {
		if (confirm(`Are you sure you want to delete ${this.state.title} widget?`)) {
			this.props.deleteWidget(this.props.dashboard_widget_uuid, this.props.position);
		}
	}

	handleSelectedReportChange = (e) => {

		const selectedReport = this.state.reportsList[e.target.value]

		if (!this.props.staged) {
			if (confirm('Selecting a new report will permanently change this widget. Proceed?')) {
				this.setState({
					reportData: selectedReport,
					title: selectedReport.name,
					properties: { ...this.state.properties, report: selectedReport.name, report_uuid: selectedReport.report_uuid }
				}, () => {
					this.addWidget('reportChange')
				})
			}
		} else {
			this.setState({
				reportData: selectedReport,
				title: selectedReport.name,
				properties: { ...this.state.properties, report: selectedReport.name, report_uuid: selectedReport.report_uuid }
			})
		}
	}

	render() {
		const { widgetEditMode, reportData, reportsList, title, chartType, reportingParams, properties } = this.state;
		let editWidgetTitle;

		if (this.props.staged) {
			editWidgetTitle = 'Chart';
		} else {
			editWidgetTitle = this.props.title;
		}

		return (
			<React.Fragment>
				{widgetEditMode &&
					<Dialog
						open={widgetEditMode ? true : false}
						onClose={this.handleClose}
						aria-labelledby="form-dialog-title"
						className="widget__dialog"
					>
						<DialogTitle id="form-dialog-title">{`${editWidgetTitle} Widget Options`}</DialogTitle>
						<DialogContent>
							<div className="widget__options">
								<div>
									<span>{this.props.staged ? 'Choose A Report' : 'Report'}</span>
									<select id="selectedReport" onChange={(e) => this.handleSelectedReportChange(e)}>
										<option></option>
										{getReportsOptions(reportsList, 'chart', properties)}
									</select>
								</div>
								{this.state.reportData && <React.Fragment>
									<div>
										<span>Title</span>
										<input type='text' value={title} onChange={(e) => this.onTitleChange(e.target.value)}></input>
									</div>
									<div>
										<span>{this.props.staged ? 'Choose A Chart Type' : 'Chart Type'}</span>
										<select id="selectedReport" value={chartType} onChange={(e) => this.setChartType(e.target.value)}>
											<option></option>
											{reportData.outputs.length > 2 &&
												<option value='bar'>Bar</option>
											}
											{reportData.outputs.length === 2 &&
												<React.Fragment>
													<option value='bar'>Bar</option>
													<option value='donut'>Donut</option>
													<option value='pie'>Pie</option>
												</React.Fragment>
											}
											{reportData.outputs.length === 1 &&
												<option value='gauge'>Gauge</option>
											}
										</select>
									</div>
									{reportingParams && reportData &&
										<div style={_.isEmpty(this.state.reportData.inputs) ? { display: 'none' } : null}>
											<span>Filters</span>
											<div className="widget__options-groupSelection">
												<button onClick={() => this.inputFiltersRef.current.handleClear()}>Clear</button>
											</div>
											<TableInputsFilter
												data={reportData}
												params={properties.reportingParams ? properties.reportingParams : reportingParams}
												widget={true}
												ref={this.inputFiltersRef}
											/>
										</div>}
								</React.Fragment>}
							</div>
						</DialogContent>
						<DialogActions>
							{this.state.reportData && this.state.chartType && <button className="medButtonPrimary" onClick={() => this.addWidget()}>{this.props.staged ? 'Set Widget' : 'Update Widget'}</button>}
							<button className="medButtonSecondary" onClick={() => this.cancelEdit()}>Cancel</button>
						</DialogActions>
					</Dialog>}
				<div className="widget widget__chart" id={this.props.dashboard_widget_uuid}>
					<span className="widget__title">{this.props.title}
						<div className="widget__filters">
							{filterCounter(properties, reportData) > 0 &&
								<div>
									<Tooltip text="Active Filters" noIcon={true} position="top" />
									<FilterListIcon fontSize="inherit" color="inherit" />
								</div>
							}
						</div>
					</span>
					<div className='widget__chart-wrapper' id={`widget__chart-${this.props.position}`} />
					{this.props.editMode && !this.props.staged && this.state.reportData &&
						<div className="widget__controls widget__controls-editMode">
							<React.Fragment>
								<div>
									<EditRoundedIcon onClick={() => this.toggleWidgetEditMode()} fontSize="inherit" />
								</div>
								<div className="widget__controls-delete">
									<DeleteForeverIcon onClick={() => this.deleteWidget()} fontSize="inherit" />
								</div>
							</React.Fragment>
						</div>
					}
				</div>
			</React.Fragment>
		);
	}
}

export default ChartWidget;