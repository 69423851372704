import React, { useEffect, useState } from "react";
import { getURLParentObject } from "../../../utils/url";
import { getSampleReport } from "../../../api/sampleReportsAPI";
// import { BasicSampleReportFields } from "./BasicSampleReportFields";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";

export const BasicSampleReportFields = [
    { id: "name", label: "Report Configuration Name" },
    { id: "outfall_name", label: "Outfall Name" },
    {
        id: "assigned_to",
        label: "Assigned To",
        inputType: "select",
        options: [
            { label: "Industry", value: "property" },
            { label: "Authority", value: "organization" },
        ],
    },
    {
        id: "lab_report_required",
        label: "Lab Report",
        inputType: "select",
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
    },
    {
        id: "custody_chain_required",
        label: "Chain of Custody",
        inputType: "select",
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
    },
    {
        id: "report_schedule",
        label: "Report Schedule",
        inputType: "schedule",
    },
];

const ViewSampleReport = () => {
    const { id: sampleReportId } = getURLParentObject("sr") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState({});

    useEffect(() => {
        setLoading(true);
        setError(null);

        getSampleReport()
            .then(setReportData)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [sampleReportId]);

    return (
        <Page loading={loading} error={error}>
            <Card title="Sample Report">
                <SummaryGrid
                    fields={BasicSampleReportFields}
                    formState={reportData}
                    separateFields={["report_schedule"]}
                />
            </Card>
        </Page>
    );
};

export default ViewSampleReport;
