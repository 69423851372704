import React, { useState, useEffect, useRef } from "react";
import UserDetailsForm from "./UserDetailsForm";
import editModeIcon from "../../../images/editMode.svg";
import {
    getUser,
    updateUser,
    inviteUser,
    removeUserRoles,
    getUserRoles,
    addUserRole,
    removeUserRole,
    checkCertificationCompliance,
    updateAutoAcceptSetting,
    removeAutoAcceptSetting,
    listAutoAcceptSettings,
} from "../../api/usersAPI";
import {
    getServiceProviderUsersReport,
    setServiceProviderUserStatus,
    removeServiceProviderUser,
    createServiceProviderUser,
} from "../../api/serviceProvidersAPI";
import { getReportValue } from "../../utils/reporting";
import { hasRole } from "../../api/permissionsAPI";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogTable from "../DialogTable";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloseIcon from "@material-ui/icons/Close";
import differenceBy from "lodash/differenceBy";

const UserDetails = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [data, setData] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [canLogin, setCanLogin] = useState(true);
    const [chooseSPActive, setChooseSPActive] = useState(false);
    const [selectedSP, setSelectedSP] = useState(null);
    const [dialogReportingParams, setDialogReportingParams] = useState(null);
    const [activeCertExpiration, setActiveCertExpiration] = useState(null);
    const [isSCAdmin, setIsSCAdmin] = useState(false);
    const [apiUserErrors, setApiUserErrors] = useState([]);
    const [AASettings, setAASettings] = useState([]);
    const [originalAASettings, setOriginalAASettings] = useState([]);

    const AASettingsRef = useRef([]);

    useEffect(() => {
        if (props.newAddition) {
            setEditMode(true);
            if (props.userSP) {
                setSelectedSP({
                    name: props.userSP.name,
                    service_provider: props.userSP.service_provider_uuid,
                });
            }
        } else {
            getUserData(props.uuid);
            getAASettings();
            if (props.data && props.data.user_type === "service provider") {
                getCertData();
            }
        }
        hasRole("SwiftComply.Admin").then((hasRole) => {
            if (hasRole) {
                setIsSCAdmin(true);
            }
        });
    }, []);

    const getUserData = async (uuid, update) => {
        let userData = props.data;
        // only hit the API if we don't already have populated data
        if (userData === undefined || userData === {} || userData.user_uuid == "" || update) {
            userData = await getUser(uuid);
        }

        getUserRoles(uuid).then((response) => {
            // start with the default values for these, if we find they are an admin or can't login we will set them
            // appropriately and then set the "state" variables once at the end
            let isAdminSetting = false;
            let canLoginSetting = true;
            if (response.roles.find((role) => role.role_name == "Org.Admin")) {
                isAdminSetting = true;
            }
            if (response.roles.find((role) => role.role_name == "Default.NoLogin")) {
                canLoginSetting = false;
            }
            setIsAdmin(isAdminSetting);
            setCanLogin(canLoginSetting);
        });

        if (SwiftComply.user.user_type === "normal" && userData.user_type === "service provider") {
            let report = await getServiceProviderUsersReport(undefined, {
                inputs: { "User UUID": userData.user_uuid },
            });
            userData.status = "";
            report.data.rows.forEach((row) => {
                if (props.sourceUUID) {
                    if (props.sourceUUID === getReportValue(report.data, row, "Service Provider UUID")) {
                        let status = getReportValue(report.data, row, "Status").toLowerCase();
                        if (status === "denied") {
                            userData.status = "Denied";
                        } else if (userData.status !== "Denied" && status === "pending") {
                            userData.status = "Pending";
                        } else if (
                            userData.status !== "Denied" &&
                            userData.status !== "Pending" &&
                            status === "approved"
                        ) {
                            userData.status = "Approved";
                        }
                        let approved_until = getReportValue(report.data, row, "Approved Until");
                        if (!userData.approved_until || approved_until > userData.approved_until) {
                            // this will come to use as a string and in seconds,
                            userData.approved_until = parseInt(approved_until, 10);
                        }
                    }
                }
            });
        }

        setData(userData);

        props.setHeader(userData.firstname + " " + userData.lastname);
    };

    const getAASettings = async () => {
        const settings = await listAutoAcceptSettings(props.uuid);
        setAASettings(settings.data["settings"]);
        setOriginalAASettings(settings.data["settings"]);
    };

    const getCertData = async () => {
        const certData = await checkCertificationCompliance(props.uuid);

        if (certData.is_compliant) {
            setActiveCertExpiration(certData.compliant_until);
        }
    };

    const saveDetails = (payload, newAddition, nextStep) => {
        if (newAddition || nextStep === "invite") {
            inviteUser(payload).then((response) => {
                if (selectedSP) {
                    createServiceProviderUser(selectedSP.service_provider, {
                        user_uuid: response.user_uuid,
                        service_provider_uuid: selectedSP.service_provider,
                    });
                }

                AASettingsRef.current["settings"].forEach((setting) => {
                    updateAutoAcceptSetting(response.user_uuid, setting.form_type, {
                        auto_accept: JSON.parse(setting.auto_accept),
                    });
                });

                props.refresh();
                props.closeFlyout();
                if (nextStep === "persist" || nextStep === "invite") {
                    setTimeout(() => {
                        props.openFlyout({ user_uuid: response.user_uuid });
                    }, 500);
                } else if (nextStep === "add") {
                    setTimeout(() => {
                        props.openFlyout(null, true);
                    }, 500);
                }
            });
        } else {
            if (payload.can_login) {
                if (!canLogin) {
                    removeUserRole(props.uuid, "Default.NoLogin").then(() => {
                        getUserData(props.uuid);
                        setEditMode(false);
                    });
                }
            } else if (!payload.can_login) {
                if (canLogin) {
                    addUserRole(props.uuid, "Default.NoLogin").then(() => {
                        getUserData(props.uuid);
                        setEditMode(false);
                    });
                }
            }
            if (payload.role_name === "Org.Admin") {
                if (!isAdmin) {
                    addUserRole(props.uuid, "Org.Admin").then(() => {
                        removeUserRole(props.uuid, "Org.User");
                        getUserData(props.uuid);
                        setEditMode(false);
                    });
                }
            } else if (payload.role_name === "Org.User") {
                if (isAdmin) {
                    addUserRole(props.uuid, "Org.User").then(() => {
                        removeUserRole(props.uuid, "Org.Admin").then(() => {
                            getUserData(props.uuid);
                            setEditMode(false);
                        });
                    });
                }
            }

            const settingsToRemove = differenceBy(originalAASettings, AASettingsRef.current["settings"], "form_type");
            const settingsToUpdate = differenceBy(AASettingsRef.current["settings"], originalAASettings, "auto_accept");

            const promises = [];
            settingsToUpdate.forEach((setting, idx) => {
                const promise = updateAutoAcceptSetting(props.uuid, setting.form_type, {
                    auto_accept: JSON.parse(setting.auto_accept),
                });
                promises.push(promise);
            });
            settingsToRemove.forEach((setting, idx) => {
                const promise = removeAutoAcceptSetting(props.uuid, setting.form_type);
                promises.push(promise);
            });

            Promise.all(promises).then((results) => {
                getAASettings();
            });

            if (isSCAdmin) {
                if (
                    data.firstname != payload.user.firstname ||
                    data.lastname != payload.user.lastname ||
                    data.email != payload.user.email ||
                    data.phone != payload.user.phone ||
                    data.user_type != payload.user.user_type ||
                    data.job_title != payload.user.job_title
                ) {
                    updateUser(data.user_uuid, {
                        firstname: payload.user.firstname,
                        lastname: payload.user.lastname,
                        email: payload.user.email,
                        phone: payload.user.phone,
                        user_type: payload.user.user_type,
                        job_title: payload.user.job_title,
                    })
                        .then((response) => {
                            setEditMode(false);
                            getUserData(props.uuid, true);
                            props.refresh();
                        })
                        .catch((error) => {
                            if (error.message.includes("409")) {
                                setApiUserErrors([
                                    ...apiUserErrors,
                                    {
                                        type: "email in use",
                                    },
                                ]);
                            }
                        });
                }
            }
        }
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const approveProviderUser = async (values, nextCert) => {
        let approvedUntilDate;

        if (nextCert) {
            approvedUntilDate = nextCert;
        } else {
            approvedUntilDate = values.approved_until;
        }
        // if accessing user details from an individual service provider, approve only that SP
        if (props.sourceUUID) {
            setServiceProviderUserStatus(props.sourceUUID, data.user_uuid, "approved", {
                approved_until: approvedUntilDate,
            });
        } else {
            // for now we are just going to approve a user for all providers they are a member of
            let report = await getServiceProviderUsersReport(undefined, { inputs: { "User UUID": data.user_uuid } });
            report.data.rows.forEach((row) => {
                setServiceProviderUserStatus(
                    getReportValue(report.data, row, "Service Provider UUID"),
                    data.user_uuid,
                    "approved",
                    {
                        approved_until: approvedUntilDate,
                    }
                );
            });
        }
        setData({ ...data, status: "Approved", approved_until: approvedUntilDate });
        props.refresh();
    };

    const denyProviderUser = async () => {
        if (props.sourceUUID) {
            setServiceProviderUserStatus(props.sourceUUID, data.user_uuid, "denied", { approved_until: null });
        } else {
            // like approval, this operates on all providers the user is a member of
            let report = await getServiceProviderUsersReport(undefined, { inputs: { "User UUID": data.user_uuid } });
            report.data.rows.forEach((row) => {
                setServiceProviderUserStatus(
                    getReportValue(report.data, row, "Service Provider UUID"),
                    data.user_uuid,
                    "denied"
                );
            });
        }
        setData({ ...data, status: "Denied", approved_until: null });
        props.refresh();
    };

    const toggleDialog = (type) => {
        if (type === "open") {
            setChooseSPActive(true);
        } else if (type === "close") {
            setChooseSPActive(false);
            setDialogReportingParams(null);
        }
    };

    const handleSelectSP = (payload) => {
        setSelectedSP({ service_provider: payload.values[0], name: payload.values[1] });
    };

    const clearSelectedSP = () => {
        setSelectedSP(null);
    };

    const removeSPUser = () => {
        removeServiceProviderUser(props.sourceUUID, props.uuid);
        setTimeout(() => {
            props.refresh();
        }, 300);
        props.closeFlyout();
    };

    const deleteUser = async (data, type) => {
        if (type === "delete") {
            removeUserRoles(props.uuid);
            setEditMode(false);
            setDeleteMode(false);
            setTimeout(() => {
                props.refresh();
            }, 300);
            props.closeFlyout();
        } else if (type === "cancel") {
            setDeleteMode(false);
        } else {
            setDeleteMode(true);
            setTimeout(() => {
                document.getElementById("flyoutBase").scrollIntoView(false);
            }, 100);
        }
    };

    return (
        <div className="flyoutContentContainer" id="flyoutBase">
            {SwiftComply.user.user_uuid !== props.uuid && (
                <button className="editModeButton" onClick={() => toggleEditMode()}>
                    {!editMode && <img src={editModeIcon} alt="" />}
                </button>
            )}
            <Dialog
                open={chooseSPActive}
                aria-labelledby="form-dialog-title"
                className="widget__dialog"
                id="dialogTable"
            >
                <DialogContent>
                    <div className="widget__options">
                        <div>
                            <span>Choose a Service Provider</span>
                            {/*<React.Fragment>
								<div className="widget__dialog-search">
									<TextField
										label="Service Provider Name"
										name="name"
										id="name"
										variant="outlined"
									/>
									<button className="xsmallButtonPrimary" onClick={() => spSearch()}>
										<SearchIcon fontSize='large' />
									</button>
								</div>
							</React.Fragment>*/}
                            <DialogTable
                                tableType="Service Providers"
                                openFlyout={handleSelectSP}
                                reportUUID="0a3d6a20-46df-47b9-9321-a8cc9a10bb75"
                                columnsToShow={["Name", "Address"]}
                                inputs={dialogReportingParams}
                                searches={["name"]}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="contactLocation__wrapper">
                        {selectedSP && (
                            <button className="medButtonPrimary" onClick={() => toggleDialog("close")}>
                                Add
                            </button>
                        )}
                        <button className="medButtonSecondary" onClick={() => toggleDialog("close")}>
                            Cancel
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            <UserDetailsForm
                {...props}
                parentData={data}
                apiUserErrors={apiUserErrors}
                isAdmin={isAdmin}
                canLogin={canLogin}
                editMode={editMode}
                saveDetails={saveDetails}
                toggleEditMode={toggleEditMode}
                approveProviderUser={approveProviderUser}
                denyProviderUser={denyProviderUser}
                toggleDialog={toggleDialog}
                selectedSP={selectedSP}
                clearSelectedSP={clearSelectedSP}
                activeCertExpiration={activeCertExpiration}
                isSCAdmin={isSCAdmin}
                apiUserErrors={apiUserErrors}
                setApiUserErrors={setApiUserErrors}
                AASettingsRef={AASettingsRef}
                AASettings={AASettings}
                getAASettings={getAASettings}
            />
            {props.isServiceProviderUser && props.sourceUUID && editMode && (
                <div className="formButtonsBank">
                    <button className="medButtonSecondary" onClick={() => removeSPUser()}>
                        Remove from Service Provider
                    </button>
                </div>
            )}
            {editMode && !deleteMode && !props.newAddition && (
                <div className="deleteButton__container">
                    <button className="deleteButton" onClick={() => deleteUser()}>
                        <DeleteForeverIcon fontSize="inherit" color="inherit" />
                    </button>
                </div>
            )}
            {deleteMode && editMode && (
                <React.Fragment>
                    <div className="flyout__deleteMode">
                        <span>Warning: These actions cannot be undone.</span>
                        <button className="medButtonSecondary" onClick={() => deleteUser(data, "delete")}>
                            Delete User
                        </button>
                        <button className="exit" onClick={() => deleteUser(null, "cancel")}>
                            <CloseIcon fontSize="large" />
                        </button>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default UserDetails;
