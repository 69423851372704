import React, { useEffect, useState } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { getRecurrences } from "../../../../api/analytesAPI";
import { dateToAPIString, formatDate } from "../../../../utils/forms";
import styles from "../../../../styles/common/schedulingDialog.module.css";
import Form from "../Form";
import Button from "../../Button";
import Divider from "../../Divider";

const SchedulingDialog = ({
    title,
    open,
    onClose,
    value = {
        schedule_type: "rep",
        day_or_date: "date",
        interval: "1",
        day_of_week: "MO",
        nth: "1",
        start: dateToAPIString(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
        until: dateToAPIString(new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)),
        ad_hoc_date: dateToAPIString(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
    },
    setValue,
    minDate,
    maxDate,
}) => {
    const [formState, setFormState] = useState(value);
    const [dates, setDates] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDates([]);
    }, [
        formState.frequency,
        formState.interval,
        formState.day_or_date,
        formState.nth,
        formState.day_of_week,
        formState.start,
        formState.until,
    ]);

    const showPreviewDates = () => {
        setLoading(true);

        getRecurrences(formState)
            .then((data) => {
                setValidationErrors([]);
                setDates(data.date_times);
            })
            .catch((error) => {
                setDates([]);
                setValidationErrors(
                    error.response?.data?.Details?.map((detail) => ({
                        id: detail.field === "weekday" ? "day_of_week" : detail.field,
                        error: detail.description,
                    })) || []
                );
            })
            .finally(() => setLoading(false));
    };

    const onApply = () => {
        setValue(formState);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle classes={{ root: styles.title }}>{title}</DialogTitle>
            <DialogContent>
                <Form
                    fields={[
                        {
                            id: "schedule_type",
                            label: "",
                            inputType: "radio",
                            options: [
                                { label: "One time", value: "one" },
                                { label: "Repeating", value: "rep" },
                            ],
                        },
                        {
                            id: "ad_hoc_date",
                            label: "Due date",
                            inputType: "date_time",
                            required: true,
                            hideWhen: (formState) => formState.schedule_type !== "one",
                            minDate: minDate,
                            maxDate: maxDate,
                        },
                        {
                            id: "frequency",
                            label: "Frequency",
                            inputType: "select",
                            options: [
                                { label: "Daily", value: "daily" },
                                { label: "Weekly", value: "weekly" },
                                { label: "Monthly", value: "monthly" },
                                { label: "Annually", value: "annually" },
                            ],
                            required: true,
                            hideWhen: (formState) => formState.schedule_type !== "rep",
                        },
                        {
                            id: "interval",
                            label: "Interval",
                            dataType: "numeric",
                            hideWhen: (formState) => formState.schedule_type !== "rep",
                        },
                        {
                            id: "day_or_date",
                            label: "Day of Month",
                            inputType: "select",
                            options: [
                                { label: "On the same date every month", value: "date" },
                                { label: "On the Nth weekday every month", value: "weekday" },
                                { label: "On the last day of the month", value: "last" },
                            ],
                            hideWhen: (formState) =>
                                formState.schedule_type !== "rep" || formState.frequency !== "monthly",
                        },
                        {
                            id: "nth",
                            label: "On the",
                            inputType: "select",
                            options: [
                                { label: "First", value: "1" },
                                { label: "Second", value: "2" },
                                { label: "Third", value: "3" },
                                { label: "Fourth", value: "4" },
                                { label: "Last", value: "-1" },
                            ],
                            required: true,
                            hideWhen: (formState) =>
                                formState.schedule_type !== "rep" ||
                                formState.frequency !== "monthly" ||
                                formState.day_or_date !== "weekday",
                        },
                        {
                            id: "day_of_week",
                            label: "Weekday",
                            inputType: "radio",
                            options: [
                                { label: "Mon", value: "MO" },
                                { label: "Tue", value: "TU" },
                                { label: "Wed", value: "WE" },
                                { label: "Thu", value: "TH" },
                                { label: "Fri", value: "FR" },
                                { label: "Sat", value: "SA" },
                                { label: "Sun", value: "SU" },
                            ],
                            required: true,
                            hideWhen: (formState) =>
                                formState.schedule_type !== "rep" ||
                                formState.frequency !== "monthly" ||
                                formState.day_or_date !== "weekday",
                        },
                        {
                            id: "start",
                            label: "Start",
                            inputType: "date_time",
                            required: true,
                            hideWhen: (formState) => formState.schedule_type !== "rep",
                            minDate: minDate,
                            maxDate: maxDate,
                        },
                        {
                            id: "until",
                            label: "Until",
                            inputType: "date_time",
                            required: true,
                            hideWhen: (formState) => formState.schedule_type !== "rep",
                            minDate: minDate,
                            maxDate: maxDate,
                        },
                    ]}
                    formState={formState}
                    onFormStateChange={setFormState}
                    validationErrors={validationErrors}
                    onValidationErrorsChange={setValidationErrors}
                />
                {formState.schedule_type === "rep" && (
                    <>
                        <Divider />
                        {loading ? (
                            <CircularProgress />
                        ) : dates.length ? (
                            <>
                                <h3>Preview ({dates.length} dates):</h3>
                                <ul>
                                    {dates.map((d) => (
                                        <li key={d}>{formatDate(d)}</li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <Button color="secondary" onClick={showPreviewDates} size="small">
                                Show dates
                            </Button>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={onApply}
                    disabled={formState.schedule_type === "rep" ? !dates?.length : !formState.ad_hoc_date}
                >
                    Set schedule
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SchedulingDialog;
